import React, { useState, useEffect } from "react";
import { getStaffMembers } from "../../services/staffService";
import { Link } from "react-router-dom";

const StaffMembers = ({ setActiveComponent, setSelectedStaffSlug }) => {
    const [staffMembers, setStaffMembers] = useState([]);

    useEffect(() => {
        const fetchStaffMembers = async () => {
            const fetchedStaffMembers = await getStaffMembers();
            setStaffMembers(fetchedStaffMembers);
        };

        fetchStaffMembers();
    }, []);

    return (
        <div className="p-6">
            <h1 className="text-2xl font-bold mb-5">Staff Members</h1>
            {staffMembers.map((staff) => (
                <div
                    key={staff.id}
                    className="bg-gray-100 p-4 rounded mb-2 grid grid-cols-1 md:grid-cols-3 gap-4"
                >
                    <div className="md:col-span-2 font-semibold">
                        {staff.name} ({staff.slug})
                    </div>
                    <div className="md:col-span-1 flex justify-end items-center">
                        <button
                            onClick={() => {
                                setSelectedStaffSlug(staff.slug);
                                setActiveComponent("staffTransactions");
                            }}
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded"
                        >
                            View Transactions
                        </button>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default StaffMembers;
