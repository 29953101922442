import { db } from "../firebase-config";
import {
    collection,
    getDocs,
    doc,
    getDoc,
    query,
    where,
    updateDoc,
    setDoc,
    orderBy,
} from "firebase/firestore";
import { getAuthorDetails } from "./authorService";
import { formatDateAsString } from "../utils/helperUtils";

export const getAllBooks = async () => {
    try {
        const booksCollection = collection(db, "books");
        const booksSnapshot = await getDocs(booksCollection);
        const booksList = booksSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
        }));
        return booksList;
    } catch (error) {
        console.error("Error fetching books:", error);
        throw error;
    }
};

export const getBooksByAuthor = async (authorId) => {
    const q = query(
        collection(db, "books"),
        where("author", "==", doc(db, "authors", authorId))
    );
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
    }));
};

export const getBooksByAuthorSlug = async (slug) => {
    const authorSnap = await getAuthorDetails(slug);
    if (authorSnap) {
        return getBooksByAuthor(authorSnap.id); // Reuse the existing function
    } else {
        return [];
    }
};

export const getBookBySlug = async (slug) => {
    const bookQuery = query(collection(db, "books"), where("slug", "==", slug));
    const querySnapshot = await getDocs(bookQuery);
    if (!querySnapshot.empty) {
        return {
            id: querySnapshot.docs[0].id,
            ...querySnapshot.docs[0].data(),
        };
    } else {
        return null;
    }
};

export const getBookByIdentifier = async (identifier) => {
    const q = query(
        collection(db, "books"),
        where("identifiers", "array-contains", identifier)
    );
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
        return null;
    }
    // Ensure that the book data includes the author reference
    const bookDoc = querySnapshot.docs[0];
    const bookData = bookDoc.data();
    bookData.id = bookDoc.id; // Set the document ID explicitly
    if (!bookData.author) {
        console.error(
            "Book data does not contain an author reference:",
            bookData
        );
        return null;
    }
    return bookData;
};

export const aggregateSalesData = async (bookId, newTransactions) => {
    const formatSalesPeriod = (salesPeriod) => {
        if (!salesPeriod) {
            console.error("Sales period is undefined or null");
            return null;
        }
        const [month, year] = salesPeriod.split("/");
        if (!month || !year) {
            console.error("Invalid sales period format:", salesPeriod);
            return null;
        }
        const fullYear = `20${year}`; // Assuming 21st century
        return `${fullYear}-${month.padStart(2, "0")}`;
    };

    try {
        const bookRef = doc(db, "books", bookId);

        for (const transaction of newTransactions) {
            console.log("Processing transaction:", transaction);

            if (!transaction.salesPeriod) {
                console.error(
                    "Transaction is missing salesPeriod:",
                    transaction
                );
                continue;
            }

            const dateString = formatSalesPeriod(transaction.salesPeriod);
            if (!dateString) {
                console.error(
                    "Failed to format sales period:",
                    transaction.salesPeriod
                );
                continue;
            }

            const monthRef = doc(bookRef, "sales_data", dateString);

            const monthSnap = await getDoc(monthRef);
            if (monthSnap.exists()) {
                // Document exists, update it
                const existingData = monthSnap.data();
                let formatIndex = existingData.formats.findIndex(
                    (f) => f.name === transaction.format
                );

                if (formatIndex !== -1) {
                    // Format exists, update it
                    existingData.formats[formatIndex] = {
                        ...existingData.formats[formatIndex],
                        copies:
                            existingData.formats[formatIndex].copies +
                            transaction.netUnitsSold,
                        amount: parseFloat(
                            (
                                existingData.formats[formatIndex].amount +
                                parseFloat(transaction.royalty)
                            ).toFixed(2)
                        ),
                    };
                } else {
                    // Format doesn't exist, add it
                    existingData.formats.push({
                        name: transaction.format,
                        copies: transaction.netUnitsSold,
                        amount: parseFloat(transaction.royalty),
                    });
                }

                await updateDoc(monthRef, {
                    formats: existingData.formats,
                });
            } else {
                // Document does not exist, create it
                const newDoc = {
                    month: dateString,
                    formats: [
                        {
                            name: transaction.format,
                            copies: transaction.netUnitsSold,
                            amount: parseFloat(transaction.royalty),
                        },
                    ],
                };
                console.log("Creating new document:", newDoc);
                await setDoc(monthRef, newDoc);
            }
        }
    } catch (error) {
        console.error("Error in aggregateSalesData:", error);
        throw error;
    }
};

export const getSalesDataByBookId = async (bookId) => {
    const bookRef = doc(db, "books", bookId);
    const salesDataRef = collection(bookRef, "sales_data");
    const sortedQuery = query(salesDataRef, orderBy("month", "desc")); // Sorting by month in descending order
    try {
        const snapshot = await getDocs(sortedQuery);
        const salesData = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
        }));
        return salesData;
    } catch (error) {
        console.error(
            `Failed to fetch sales data for book ID: ${bookId}`,
            error
        );
        throw new Error(`Error fetching sales data: ${error.message}`);
    }
};
