export const formatDateAsString = (date, format) => {
    const d = new Date(date);
    const day = d.getDate();
    const month = d.getMonth() + 1; // getMonth() returns 0-11
    const year = d.getFullYear();

    let formattedDate = format
        .replace("YYYY", year)
        .replace("MM", month.toString().padStart(2, "0"))
        .replace("DD", day.toString().padStart(2, "0"));

    return formattedDate;
};

export const parseAmount = (amount) => {
    if (typeof amount === "string") {
        return parseFloat(amount.replace(/[^\d.-]/g, ""));
    }
    return parseFloat(amount); // Assumes amount can also be a number, just in case
};

export const getMonths = () => [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

export const formatSalesPeriod = (salesPeriodOrMonth, year) => {
    const months = {
        January: "1",
        February: "2",
        March: "3",
        April: "4",
        May: "5",
        June: "6",
        July: "7",
        August: "8",
        September: "9",
        October: "10",
        November: "11",
        December: "12",
    };

    if (year) {
        // If year is provided, we assume salesPeriodOrMonth is the month
        const formattedMonth = months[salesPeriodOrMonth];
        const formattedYear = year.slice(2); // Get last two digits of the year
        return `${formattedMonth}/${formattedYear}`;
    } else {
        // Assume salesPeriodOrMonth is a string like "April 2024"
        const parts = salesPeriodOrMonth.split(" ");
        const month = parts[0];
        const formattedMonth = months[month];
        const formattedYear = parts[1].slice(2); // Get last two digits of the year
        return `${formattedMonth}/${formattedYear}`;
    }
};

export const getYears = () => {
    const currentYear = new Date().getFullYear();
    return Array.from({ length: 10 }, (_, index) => currentYear - index); // last 10 years
};
