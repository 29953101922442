import React from "react";

const SalesChannelDropdown = ({ defaultValue, onChange }) => {
    const categories = [
        { id: "amazon-us", name: "Amazon.com" },
        { id: "amazon-ca", name: "Amazon.ca" },
        { id: "amazon-uk", name: "Amazon.co.uk" },
        { id: "amazon-au", name: "Amazon.com.au" },
        { id: "itunes", name: "itunes" },
        { id: "acx", name: "ACX" },
        { id: "kobo", name: "Kobo" },
        { id: "google", name: "Google" },
        { id: "ingram", name: "Ingram" },
        { id: "b-n", name: "B & N" },
        { id: "amazon-de", name: "Amazon.de" },
        { id: "amazon-it", name: "Amazon.it" },
        { id: "amazon-fr", name: "Amazon.fr" },
        { id: "amazon-in", name: "Amazon.in" },
        { id: "amazon-es", name: "Amazon.es" },
        { id: "amazon-br", name: "Amazon.br" },
        { id: "amazon-jp", name: "Amazon.jp" },
        { id: "amazon-nl", name: "Amazon.nl" },
        { id: "amazon-mx", name: "Amazon.mx" },
        { id: "amazon-pl", name: "Amazon.pl" },
        { id: "amazon-se", name: "Amazon.se" },
    ];
    return (
        <select
            value={defaultValue}
            onChange={onChange}
            className="shadow border rounded py-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        >
            <option value="">Select a Sales Channel</option>
            {categories.map((saleChannel) => (
                <option key={saleChannel.id} value={saleChannel.name}>
                    {saleChannel.name}
                </option>
            ))}
        </select>
    );
};

export default SalesChannelDropdown;
