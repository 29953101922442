import React, { useState, useEffect } from "react";
import { db } from "../firebase-config";
import { collection, query, where, getDocs } from "firebase/firestore";
import TransactionItem from "./TransactionItem";
import TransactionProcessor from "./TransactionProcessor";
import AmazonTransactionProcessor from "./AmazonTransactionProcessor";
import AdjustmentProcessor from "./AdjustmentProcessor";
import { PulseLoader } from "react-spinners";

const CategorizeTransactions = ({
    moveToNextStep,
    setAllTransactionsCategorized,
}) => {
    const [transactions, setTransactions] = useState([]);
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [loading, setLoading] = useState(true);
    const [transactionProcessed, setTransactionProcessed] = useState(false);

    const removeProcessedTransactions = (processedIds) => {
        const filteredTransactions = transactions.filter(
            (transaction) => !processedIds.includes(transaction.id)
        );
        setTransactions(filteredTransactions);
        setTransactionProcessed(true);
    };

    useEffect(() => {
        const fetchTransactions = async () => {
            setLoading(true);
            const transQuery = query(
                collection(db, "bankTransactions"),
                where("processed", "==", false)
            );

            try {
                const querySnapshot = await getDocs(transQuery);
                const fetchedTransactions = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                    date: doc.data().date.toDate(), // Convert Timestamp to Date if necessary
                }));
                setTransactions(fetchedTransactions);
            } catch (error) {
                console.error("Error fetching transactions:", error);
            }
            setLoading(false);
        };

        fetchTransactions();
    }, []);

    useEffect(() => {
        if (transactions.length === 0 && transactionProcessed) {
            setAllTransactionsCategorized(true);
            moveToNextStep();
        }
    }, [transactions, moveToNextStep, transactionProcessed]);

    const handleSelectTransaction = (transaction) => {
        setSelectedTransaction(transaction);
    };

    const amazonTransactions = transactions.filter((tran) =>
        tran.salesChannel?.includes("Amazon")
    );

    const publishingRevenueTransactions = transactions.filter(
        (tran) =>
            !tran.salesChannel?.includes("Amazon") &&
            tran.category === "Publishing Revenue"
    );

    const adjustmentTransactions = transactions.filter(
        (tran) => tran.category === "Adjustments"
    );

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <PulseLoader color="#36d7b7" />
            </div>
        );
    }
    return (
        <div className="space-y-4">
            {amazonTransactions.length > 0 && (
                <div className="p-4 bg-blue-100 rounded-md">
                    <AmazonTransactionProcessor
                        transactions={amazonTransactions}
                        removeProcessedTransactions={
                            removeProcessedTransactions
                        }
                    />
                </div>
            )}

            {publishingRevenueTransactions.map((transaction) => (
                <div
                    key={transaction.id}
                    className="p-4 bg-gray-100 rounded-md"
                >
                    <TransactionItem
                        transaction={transaction}
                        onSelect={handleSelectTransaction}
                    />
                    {selectedTransaction?.id === transaction.id && (
                        <TransactionProcessor
                            transaction={selectedTransaction}
                            removeProcessedTransactions={
                                removeProcessedTransactions
                            }
                        />
                    )}
                    {selectedTransaction?.id !== transaction.id && (
                        <button
                            className="mt-2 px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-700"
                            onClick={() => setSelectedTransaction(transaction)}
                        >
                            Process Transaction
                        </button>
                    )}
                </div>
            ))}

            {adjustmentTransactions.map((transaction) => (
                <div
                    key={transaction.id}
                    className="p-4 bg-gray-100 rounded-md"
                >
                    <TransactionItem
                        transaction={transaction}
                        onSelect={handleSelectTransaction}
                    />
                    {selectedTransaction?.id === transaction.id && (
                        <AdjustmentProcessor
                            transaction={selectedTransaction}
                            removeProcessedTransactions={
                                removeProcessedTransactions
                            }
                        />
                    )}
                    {selectedTransaction?.id !== transaction.id && (
                        <button
                            className="mt-2 px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-700"
                            onClick={() => setSelectedTransaction(transaction)}
                        >
                            Process Adjustment
                        </button>
                    )}
                </div>
            ))}
        </div>
    );
};

export default CategorizeTransactions;
