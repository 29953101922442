import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getBookBySlug } from "../services/bookService";
import { getSalesDataByBookId } from "../services/bookService";

const BookDetail = () => {
    const { slug } = useParams();
    const [book, setBook] = useState(null);
    const [salesData, setSalesData] = useState([]);

    useEffect(() => {
        getBookBySlug(slug).then((book) => {
            setBook(book);
            if (book) {
                getSalesDataByBookId(book.id).then(setSalesData);
            }
        });
    }, [slug]);

    if (!book) return <div>Loading...</div>;

    // Calculate total copies sold across all formats and months
    const totalCopies = salesData.reduce(
        (total, entry) =>
            total +
            entry.formats.reduce((sum, format) => sum + format.copies, 0),
        0
    );

    return (
        <div>
            <img
                src={`https://bcpc.vercel.app/_next/image?url=%2Fimages%2F${book.slug}.jpg&w=256&q=75`}
                alt={`Cover for ${book.name}`}
                width={128}
                className="mx-auto my-12"
            />
            <h3>Total Copies Sold: {totalCopies}</h3>
            <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th>Month</th>
                            <th className="text-center">Total</th>
                            <th className="text-center">Print</th>
                            <th className="text-center">eBook</th>
                            <th className="text-center">Audio</th>
                        </tr>
                    </thead>
                    <tbody>
                        {salesData.map((entry, index) => (
                            <tr key={index}>
                                <td>
                                    {new Date(entry.month).toLocaleString(
                                        "default",
                                        { month: "long", year: "numeric" }
                                    )}
                                </td>
                                <td className="text-center">
                                    <span className="font-bold">
                                        {entry.formats.reduce(
                                            (sum, format) =>
                                                sum + format.copies,
                                            0
                                        )}
                                    </span>
                                    <br />$
                                    {entry.formats
                                        .reduce(
                                            (sum, format) =>
                                                sum + format.amount,
                                            0
                                        )
                                        .toFixed(2)}
                                </td>

                                {["print", "ebook", "audio"].map((type) => {
                                    let totalCopies = 0;
                                    let totalAmount = 0;
                                    if (type === "print") {
                                        const printFormats = [
                                            "paperback",
                                            "hardcover",
                                            "print",
                                        ];
                                        printFormats.forEach((printType) => {
                                            const format = entry.formats.find(
                                                (f) => f.name === printType
                                            );
                                            if (format) {
                                                totalCopies += format.copies;
                                                totalAmount += format.amount;
                                            }
                                        });
                                    } else {
                                        const format = entry.formats.find(
                                            (f) => f.name === type
                                        );
                                        if (format) {
                                            totalCopies = format.copies;
                                            totalAmount = format.amount;
                                        }
                                    }
                                    return (
                                        <td className="text-center" key={type}>
                                            {totalCopies > 0 ? (
                                                <>
                                                    <span className="font-bold">
                                                        {totalCopies}
                                                    </span>
                                                    <br />$
                                                    {totalAmount.toFixed(2)}
                                                </>
                                            ) : (
                                                "-"
                                            )}
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default BookDetail;
