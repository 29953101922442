import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import { parseAmazonSpreadsheet } from "../utils/parsingUtils";
import { updateAuthorAndBookRecords } from "../utils/transactionUtils";
import { markBankTransactionsProcessed } from "../services/bankService";
import { PulseLoader } from "react-spinners";
import ManualAssignment from "./ManualAssignment";

const AmazonTransactionProcessor = ({
    transactions,
    removeProcessedTransactions,
}) => {
    const [files, setFiles] = useState([]);
    const [processing, setProcessing] = useState(false);
    const [uploadError, setUploadError] = useState("");
    const [marketData, setMarketData] = useState({});
    const [totalUSDAmountByChannel, setTotalUSDAmountByChannel] = useState({});
    const [readyToUpdate, setReadyToUpdate] = useState(false);
    const [parsedSpreadsheets, setParsedSpreadsheets] = useState([]);
    const [amazonTransactions, setAmazonTransactions] = useState([]);
    const [showManualAssignment, setShowManualAssignment] = useState(false);
    const [marketDataSets, setMarketDataSets] = useState([]);
    const [paymentDate, setPaymentDate] = useState("");

    useEffect(() => {
        setTotalUSDAmountByChannel(
            transactions.reduce((acc, curr) => {
                acc[curr.salesChannel] = acc[curr.salesChannel] || 0;
                acc[curr.salesChannel] += parseFloat(curr.usdAmount || 0);
                return acc;
            }, {})
        );
        if (transactions.length > 0) {
            setPaymentDate(transactions[0].date); //was transactions[0].date.toDate()
        }
    }, [transactions]);

    const handleFileChange = (e) => {
        const newFiles = Array.from(e.target.files);
        setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    };

    const clearFiles = () => {
        setFiles([]);
        // Also reset the file input
        const fileInput = document.getElementById("amazonFileInput");
        if (fileInput) fileInput.value = "";
    };
    const handleFileUpload = async () => {
        if (files.length === 0) {
            setUploadError(
                "Please select at least one file before continuing."
            );
            return;
        }
        setProcessing(true);
        const parsedData = [];

        for (const file of files) {
            const reader = new FileReader();
            await new Promise((resolve) => {
                reader.onload = async (e) => {
                    try {
                        const arrayBuffer = e.target.result;
                        const workbook = XLSX.read(arrayBuffer, {
                            type: "buffer",
                        });
                        const marketData = parseAmazonSpreadsheet(workbook);

                        const firstMarket = Object.values(marketData)[0];
                        const salesPeriod =
                            firstMarket &&
                            firstMarket[Object.keys(firstMarket)[0]][0]
                                ?.salesPeriod;

                        if (!salesPeriod) {
                            console.error(
                                `Failed to extract sales period from file: ${file.name}`
                            );
                            setUploadError(
                                `Failed to extract sales period from file: ${file.name}`
                            );
                        } else {
                            parsedData.push({
                                marketData,
                                fileName: file.name,
                            });
                        }
                    } catch (error) {
                        console.error(
                            `Error processing file ${file.name}:`,
                            error
                        );
                        setUploadError(
                            `Error processing file ${file.name}: ${error.message}`
                        );
                    }
                    resolve();
                };
                reader.readAsArrayBuffer(file);
            });
        }

        if (parsedData.length === 0) {
            setUploadError("No valid data could be extracted from the files.");
            setProcessing(false);
            return;
        }

        // Filter Amazon transactions
        const amazonTransactions = transactions.filter((t) =>
            t.salesChannel.includes("Amazon")
        );

        if (parsedData.length > 1) {
            // If there's more than one spreadsheet, go to manual assignment
            setParsedSpreadsheets(parsedData);
            setAmazonTransactions(amazonTransactions);
            setShowManualAssignment(true);
        } else {
            // If there's only one spreadsheet, process it directly
            const spreadsheet = parsedData[0];
            const firstMarket = Object.values(spreadsheet.marketData)[0];
            const salesPeriod =
                firstMarket &&
                firstMarket[Object.keys(firstMarket)[0]][0]?.salesPeriod;

            const newMarketDataSets = [
                {
                    ...spreadsheet,
                    bankTransactions: amazonTransactions.reduce(
                        (acc, transaction) => {
                            acc[transaction.salesChannel] = transaction;
                            return acc;
                        },
                        {}
                    ),
                    salesPeriod,
                },
            ];
            setMarketDataSets(newMarketDataSets);
            setReadyToUpdate(true);
        }
        setProcessing(false);
    };
    const handleManualAssignmentComplete = (newMarketDataSets) => {
        setMarketDataSets(newMarketDataSets);
        setShowManualAssignment(false);
        setReadyToUpdate(true);
    };

    const handleApproveUpdates = async () => {
        if (marketDataSets.length === 0) {
            alert("No data to update.");
            return;
        }
        try {
            setProcessing(true);
            for (const {
                marketData,
                bankTransactions,
                salesPeriod,
            } of marketDataSets) {
                console.log("Sales Period: " + salesPeriod);
                for (const [market, formatsData] of Object.entries(
                    marketData
                )) {
                    if (bankTransactions[market]) {
                        const totalUsdAmount = parseFloat(
                            bankTransactions[market].usdAmount
                        );
                        const convertedMarketData = convertRoyaltiesToUSD(
                            formatsData,
                            totalUsdAmount
                        );

                        await updateAuthorAndBookRecords(
                            { [market]: convertedMarketData },
                            bankTransactions[market],
                            salesPeriod
                        );
                    } else {
                        console.warn(
                            `No bank transaction found for ${market} in ${salesPeriod}. Skipping updates.`
                        );
                    }
                }
            }
            const processedTransactionIds = marketDataSets.flatMap((set) =>
                Object.values(set.bankTransactions).map((t) => t.id)
            );
            await markBankTransactionsProcessed(processedTransactionIds);
            await removeProcessedTransactions(processedTransactionIds);
            setReadyToUpdate(false);
            setProcessing(false);
            setFiles([]);
            setMarketDataSets([]);
        } catch (error) {
            console.error("Failed to update records:", error);
            setUploadError("Failed to update records: " + error.message);
        }
    };

    const convertRoyaltiesToUSD = (formatsData, totalUsdAmount) => {
        const convertedMarketData = {};
        const totalLocalAmount = Object.values(formatsData)
            .flat()
            .reduce(
                (sum, transaction) => sum + parseFloat(transaction.royalty),
                0
            );

        const exchangeRate = totalUsdAmount / totalLocalAmount;

        for (const [format, transactions] of Object.entries(formatsData)) {
            if (Array.isArray(transactions)) {
                convertedMarketData[format] = transactions.map(
                    (transaction) => ({
                        ...transaction,
                        royalty: (
                            parseFloat(transaction.royalty) * exchangeRate
                        ).toFixed(2),
                    })
                );
            } else {
                console.warn(
                    `Unexpected data structure for format ${format}:`,
                    transactions
                );
                convertedMarketData[format] = [
                    {
                        ...transactions,
                        royalty: (
                            parseFloat(transactions.royalty) * exchangeRate
                        ).toFixed(2),
                    },
                ];
            }
        }

        return convertedMarketData;
    };

    const sumRoyalties = (transactions) => {
        return transactions
            .reduce((total, current) => total + parseFloat(current.royalty), 0)
            .toFixed(2);
    };

    const calculateExchangeRate = (market, localTotal, usdAmount) => {
        if (usdAmount && localTotal > 0) {
            return (usdAmount / parseFloat(localTotal)).toFixed(4);
        }
        return 1; // Default to 1 if no exchange rate is available
    };

    if (processing || !marketData) {
        return (
            <div className="flex justify-center items-center h-screen">
                <PulseLoader color="#36d7b7" />
            </div>
        );
    }

    return (
        <div>
            <h2>Amazon Transactions</h2>
            <div className="flex justify-between">
                <input
                    type="file"
                    id="amazonFileInput"
                    onChange={handleFileChange}
                    accept=".xlsx,.xls,.csv"
                    className="mb-2"
                    multiple
                />
                <button
                    onClick={() =>
                        document.getElementById("amazonFileInput").click()
                    }
                    className="ml-2 px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-700"
                >
                    Select Files
                </button>
                {files.length > 0 && (
                    <button
                        onClick={clearFiles}
                        className="ml-2 px-3 py-1 bg-red-500 text-white rounded-md hover:bg-red-700"
                    >
                        Clear Files
                    </button>
                )}
            </div>
            <div>
                {files.map((file, index) => (
                    <div key={index} className="mt-1">
                        {file.name}
                    </div>
                ))}
            </div>
            {files.length > 0 && !processing && !readyToUpdate && (
                <button
                    className="mt-2 px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-700"
                    onClick={handleFileUpload}
                >
                    Upload Files
                </button>
            )}
            {uploadError && <p className="text-red-500">{uploadError}</p>}
            {readyToUpdate && (
                <button
                    className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-700"
                    onClick={handleApproveUpdates}
                >
                    Approve Updates
                </button>
            )}
            {showManualAssignment && (
                <ManualAssignment
                    parsedSpreadsheets={parsedSpreadsheets}
                    amazonTransactions={amazonTransactions}
                    onAssignmentComplete={handleManualAssignmentComplete}
                />
            )}
            {readyToUpdate && (
                <div>
                    <h2>Confirmation Screen</h2>
                    {marketDataSets.map(
                        (
                            {
                                marketData,
                                bankTransactions,
                                fileName,
                                salesPeriod,
                            },
                            index
                        ) => (
                            <div key={index}>
                                <h3>File: {fileName}</h3>
                                <p>Sales Period: {salesPeriod}</p>
                                {Object.entries(marketData).map(
                                    ([market, formatsData]) => (
                                        <div
                                            key={market}
                                            className="market-box"
                                        >
                                            <h4>{market} Sales</h4>
                                            <p>
                                                Total Sales: $
                                                {sumRoyalties(
                                                    [].concat(
                                                        ...Object.values(
                                                            formatsData
                                                        ).flat()
                                                    )
                                                )}
                                            </p>
                                            {bankTransactions[market] ? (
                                                <div>
                                                    <p>
                                                        Matched Bank
                                                        Transaction:
                                                    </p>
                                                    <p>
                                                        $
                                                        {
                                                            bankTransactions[
                                                                market
                                                            ].usdAmount
                                                        }{" "}
                                                        on{" "}
                                                        {bankTransactions[
                                                            market
                                                        ].date.toDateString()}
                                                    </p>
                                                    <p>
                                                        Exchange Rate:
                                                        {calculateExchangeRate(
                                                            market,
                                                            sumRoyalties(
                                                                [].concat(
                                                                    ...Object.values(
                                                                        formatsData
                                                                    ).flat()
                                                                )
                                                            ),
                                                            parseFloat(
                                                                bankTransactions[
                                                                    market
                                                                ].usdAmount
                                                            )
                                                        )}
                                                    </p>
                                                </div>
                                            ) : (
                                                <p>
                                                    No matching bank transaction
                                                    found for this market
                                                </p>
                                            )}
                                            {Object.entries(formatsData).map(
                                                ([format, transactions]) => (
                                                    <div key={format}>
                                                        <h5>{format}</h5>
                                                        {transactions.map(
                                                            (
                                                                transaction,
                                                                tIndex
                                                            ) => (
                                                                <div
                                                                    key={tIndex}
                                                                >
                                                                    <span className="italic">
                                                                        {
                                                                            transaction.title
                                                                        }{" "}
                                                                    </span>
                                                                    {
                                                                        transaction.netUnitsSold
                                                                    }{" "}
                                                                    copies for $
                                                                    {
                                                                        transaction.royalty
                                                                    }
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    )
                                )}
                            </div>
                        )
                    )}
                </div>
            )}
        </div>
    );
};
export default AmazonTransactionProcessor;
