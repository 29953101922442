import React, { useState, useMemo } from "react";
import Decimal from "decimal.js";

const TransactionsTable = ({ transactions }) => {
    const [visibleCount, setVisibleCount] = useState(50); // Start by showing 10 entries

    const showMoreTransactions = () => {
        setVisibleCount((prevCount) => prevCount + 100); // Show 10 more entries
    };

    // Remove duplicate transactions
    const uniqueTransactions = useMemo(() => {
        const uniqueSet = new Set();
        return transactions.filter((transaction) => {
            const transactionKey = `${transaction.date}-${transaction.amount}-${transaction.description}`;
            if (uniqueSet.has(transactionKey)) {
                return false;
            }
            uniqueSet.add(transactionKey);
            return true;
        });
    }, [transactions]);

    // Sort transactions by date from oldest to newest and calculate balances
    const transactionsWithBalances = useMemo(() => {
        const sortedTransactions = uniqueTransactions.sort(
            (a, b) => new Date(a.date) - new Date(b.date)
        );
        let runningBalance = new Decimal(0); // Initialize with Decimal
        return sortedTransactions
            .map((transaction) => {
                const amount = String(transaction.amount).replace(
                    /[^0-9.-]+/g,
                    ""
                );
                runningBalance = runningBalance.plus(amount); // Use Decimal for precise arithmetic
                return {
                    ...transaction,
                    balance: runningBalance.toFixed(2),
                };
            })
            .reverse(); // Reverse for display, newest at the top
    }, [uniqueTransactions]);

    return (
        <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="py-3 px-6">
                            Date
                        </th>
                        <th scope="col" className="py-3 px-6">
                            Amount
                        </th>
                        <th scope="col" className="py-3 px-6">
                            Description
                        </th>
                        <th scope="col" className="py-3 px-6">
                            Balance
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {transactionsWithBalances
                        .slice(0, visibleCount)
                        .map((transaction, index) => (
                            <tr
                                key={index}
                                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                            >
                                <td className="py-4 px-6">
                                    {transaction.date}
                                </td>
                                <td
                                    className={`py-4 px-6 ${
                                        transaction.amount.startsWith("-")
                                            ? "text-red-500"
                                            : "text-green-500"
                                    }`}
                                >
                                    {transaction.amount}
                                </td>
                                <td className="py-4 px-6">
                                    {transaction.description}
                                </td>
                                <td
                                    className={`py-4 px-6 ${
                                        parseFloat(transaction.balance) < 0
                                            ? "text-red-500"
                                            : "text-green-500"
                                    }`}
                                >
                                    ${transaction.balance}
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
            {visibleCount < transactionsWithBalances.length && (
                <button
                    onClick={showMoreTransactions}
                    className="mt-4 px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-blue-600 border border-transparent rounded-lg active:bg-blue-600 hover:bg-blue-700 focus:outline-none focus:shadow-outline-blue"
                >
                    Show More
                </button>
            )}
        </div>
    );
};

export default TransactionsTable;
