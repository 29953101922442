import React, { useState } from "react";
import { db } from "../firebase-config";
import { collection, doc, setDoc } from "firebase/firestore";
import CategoriesDropdown from "./CategoriesDropdown";
import SalesChannelDropdown from "./SalesChannelDropdown";

const slugify = (text) => {
    return text
        .toString()
        .toLowerCase()
        .replace(/\s+/g, "-") // Replace spaces with -
        .replace(/[^\w\-]+/g, "") // Remove all non-word chars
        .replace(/\-\-+/g, "-") // Replace multiple - with single -
        .replace(/^-+/, "") // Trim - from start of text
        .replace(/-+$/, ""); // Trim - from end of text
};

const AddPatternForm = () => {
    const [category, setCategory] = useState("");
    const [description, setDescription] = useState("");
    const [salesChannel, setSalesChannel] = useState("");
    const [mustMatchAll, setMustMatchAll] = useState(true);
    const [patterns, setPatterns] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError("");

        try {
            const patternArray = patterns.split(",").map((item) => item.trim());
            const newPattern = {
                category,
                description,
                salesChannel,
                triggers: [
                    {
                        mustMatchAll,
                        patterns: patternArray,
                    },
                ],
            };

            // Generate the document ID from the first trigger's patterns
            const docId = slugify(patternArray.join("-"));
            // Create a document reference with the custom ID
            const patternRef = doc(collection(db, "patterns"), docId);
            await setDoc(patternRef, newPattern);

            setCategory("");
            setDescription("");
            setSalesChannel("");
            setPatterns("");
            alert("Pattern added successfully!");
        } catch (err) {
            setError("Failed to add pattern: " + err.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="max-w-xl mx-auto p-4">
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                    Category
                </label>
                <CategoriesDropdown
                    defaultValue={category}
                    onChange={(e) => setCategory(e.target.value)}
                />
            </div>
            {category === "Publishing Revenue" && (
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                        Sales Channel
                    </label>
                    <SalesChannelDropdown
                        defaultValue={salesChannel}
                        onChange={(e) => setSalesChannel(e.target.value)}
                    />
                </div>
            )}
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                    Description
                </label>
                <input
                    type="text"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
            </div>
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                    Triggers (comma-separated)
                </label>
                <input
                    type="text"
                    value={patterns}
                    onChange={(e) => setPatterns(e.target.value)}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
            </div>
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                    Must Match All
                </label>
                <select
                    value={mustMatchAll}
                    onChange={(e) => setMustMatchAll(e.target.value === "true")}
                    className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                >
                    <option value="true">True</option>
                    <option value="false">False</option>
                </select>
            </div>
            <div className="flex items-center justify-between">
                <button
                    type="submit"
                    disabled={loading}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                    Add Pattern
                </button>
                {error && (
                    <p className="text-red-500 text-xs italic">{error}</p>
                )}
            </div>
        </form>
    );
};

export default AddPatternForm;
