import * as XLSX from "xlsx";
import { ref, getBlob } from "firebase/storage";
import Papa from "papaparse";
import { storage } from "../firebase-config";
import { categorizeTransaction } from "./transactionUtils";
import { formatSalesPeriod } from "./helperUtils";

export const parseCsvFile = async (fileName, patterns) => {
    const storageRef = ref(storage, `bank-data/${fileName}`);
    try {
        const blob = await getBlob(storageRef);
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = function (event) {
                const csvData = Papa.parse(event.target.result, {
                    header: true,
                    skipEmptyLines: true,
                    transformHeader: (header) =>
                        header.trim().replace(/\r/, ""),
                });
                if (csvData.data && csvData.data.length > 0) {
                    const processedTransactions = csvData.data.map((trans) => ({
                        ...trans,
                        ...categorizeTransaction(trans, patterns),
                        editable: !categorizeTransaction(trans, patterns).found,
                    }));
                    resolve({
                        rawData: csvData.data,
                        processedData: processedTransactions,
                    });
                } else {
                    reject(new Error("No data found in CSV or empty CSV"));
                }
            };
            reader.onerror = (error) => {
                reject(new Error("Error reading file: " + error.message));
            };
            reader.readAsText(blob);
        });
    } catch (error) {
        throw new Error("Failed to fetch or parse the file: " + error.message);
    }
};

export const parseAmazonSpreadsheet = (workbook) => {
    const marketData = {};
    let salesPeriod = "";

    workbook.SheetNames.forEach((sheetName) => {
        if (sheetName === "Total Royalty") {
            return; // Skip processing for this sheet
        }
        const sheet = workbook.Sheets[sheetName];
        if (sheet["B1"]) {
            salesPeriod = formatSalesPeriod(sheet["B1"].v);
        }

        // Skip the first two rows which contain the sales period and headers
        const transactions = XLSX.utils.sheet_to_json(sheet, { range: 1 });

        transactions.forEach((transaction) => {
            const market = transaction.Marketplace;
            if (!market) {
                return; // Skip this transaction if market is undefined
            }
            const format = determineFormat(sheet, sheetName);
            if (!marketData[market]) {
                marketData[market] = {};
            }
            if (!marketData[market][format]) {
                marketData[market][format] = [];
            }
            let netUnitsSold = transaction["Net Units Sold"] || 0;
            // For KENP, we treat the "Kindle Edition Normalized Pages (KENP)" as "Net Units Sold"
            if (sheetName === "KENP") {
                netUnitsSold = Math.round(
                    Number(
                        transaction["Kindle Edition Normalized Pages (KENP)"] /
                            250
                    ) || 0
                );
            } else if (!transaction["Net Units Sold"]) {
                // Calculate if "Net Units Sold" is not available
                netUnitsSold =
                    transaction["Units Sold"] - transaction["Units Refunded"];
            }

            marketData[market][format].push({
                title: transaction.Title,
                identifier:
                    transaction.ISBN ||
                    transaction.ASIN ||
                    transaction["eBook ASIN"] ||
                    transaction["Audiobook ASIN"] ||
                    transaction["Audible ASIN"],
                netUnitsSold: netUnitsSold,
                royalty: transaction.Royalty,
                salesPeriod: salesPeriod,
            });
        });
    });
    return marketData;
};

const determineFormat = (sheet, sheetName) => {
    const headers = XLSX.utils.sheet_to_json(sheet, { header: 1 })[1]; // Access the second row for headers
    if (sheetName.includes("eBook") || headers.includes("eBook ASIN"))
        return "ebook";
    if (sheetName.includes("Paperback")) return "paperback";
    if (sheetName.includes("Hardcover")) return "hardcover";
    if (
        sheetName.includes("Audiobook") ||
        headers.includes("Audiobook ASIN") ||
        headers.includes("Audible ASIN")
    )
        return "Audiobook";
    // Add more conditions for other formats
    return "Unknown";
};
