import React from "react";

const NavigationButtons = ({ onBack, onNext, canGoBack, canGoNext }) => {
    return (
        <div className="flex justify-between mt-4">
            <button
                onClick={onBack}
                disabled={!canGoBack}
                className={`px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-gray-500 border border-transparent rounded-lg focus:outline-none focus:shadow-outline-gray ${
                    canGoBack
                        ? "hover:bg-gray-600 active:bg-gray-700"
                        : "opacity-50 cursor-not-allowed"
                }`}
            >
                Back
            </button>
            <button
                onClick={onNext}
                disabled={!canGoNext}
                className={`px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-blue-600 border border-transparent rounded-lg focus:outline-none focus:shadow-outline-blue ${
                    canGoNext
                        ? "hover:bg-blue-700 active:bg-blue-800"
                        : "opacity-50 cursor-not-allowed"
                }`}
            >
                Next
            </button>
        </div>
    );
};

export default NavigationButtons;
