import { getTransactionsByStaff } from "../services/staffService";
import Decimal from "decimal.js";

export const calculateStaffWorkingBalance = async (staff) => {
    if (staff) {
        const mostRecentBalance =
            staff.balanceHistory[staff.balanceHistory.length - 1];
        const mostRecentBalanceDate = new Date(mostRecentBalance.date);
        const transactions = await getTransactionsByStaff(staff.id);
        const transactionsSinceLastConfirm = transactions.filter(
            (transaction) => new Date(transaction.date) >= mostRecentBalanceDate
        );
        const updatedBalance = transactionsSinceLastConfirm.reduce(
            (sum, transaction) =>
                sum.add(new Decimal(transaction.amount.replace("$", ""))), // Assuming the amount string starts with '$'
            new Decimal(mostRecentBalance.amount)
        );
        return updatedBalance.toFixed(2); // This balance is used for working calculations but not stored until confirmed
    } else {
        throw new Error("Staff not found.");
    }
};
