import React, { useState, useEffect } from "react";
import {
    getAuthors,
    addAuthor,
    updateAuthor,
    deleteAuthor,
} from "../../services/authorService";
import { Link } from "react-router-dom";

const Authors = ({ setActiveComponent, setSelectedAuthorSlug }) => {
    const [authors, setAuthors] = useState([]);
    const [newAuthorName, setNewAuthorName] = useState("");
    const [newAuthorSlug, setNewAuthorSlug] = useState("");

    useEffect(() => {
        const fetchAuthors = async () => {
            const fetchedAuthors = await getAuthors();
            setAuthors(fetchedAuthors);
        };

        fetchAuthors();
    }, []);

    const handleAddAuthor = async () => {
        await addAuthor({
            name: newAuthorName,
            slug: newAuthorSlug,
            balanceHistory: [],
        });
        setNewAuthorName("");
        setNewAuthorSlug("");
    };

    return (
        <div className="p-6">
            <h1 className="text-2xl font-bold mb-5">Manage Authors</h1>
            <div className="mb-4">
                <input
                    type="text"
                    value={newAuthorName}
                    onChange={(e) => setNewAuthorName(e.target.value)}
                    placeholder="Enter author name"
                    className="border p-2 rounded mr-2"
                />
                <input
                    type="text"
                    value={newAuthorSlug}
                    onChange={(e) => setNewAuthorSlug(e.target.value)}
                    placeholder="Enter author slug"
                    className="border p-2 rounded mr-2"
                />
                <button
                    onClick={handleAddAuthor}
                    className="bg-blue-500 text-white p-2 rounded"
                >
                    Add Author
                </button>
            </div>
            {authors.map((author) => (
                <>
                    <div
                        key={author.id}
                        className="bg-gray-100 p-4 rounded mb-2 grid grid-cols-1 md:grid-cols-3 gap-4"
                    >
                        <div className="md:col-span-2 font-semibold">
                            {author.name} ({author.slug})
                        </div>
                        <div className="flex justify-end items-center space-x-2">
                            <button
                                onClick={() =>
                                    updateAuthor(author.id, {
                                        name: author.name,
                                        slug: author.slug,
                                    })
                                }
                                className="bg-green-500 text-white p-1 rounded"
                            >
                                Update
                            </button>
                            <button
                                onClick={() => deleteAuthor(author.id)}
                                className="bg-red-500 text-white p-1 rounded"
                            >
                                Delete
                            </button>
                        </div>
                        <div className="md:col-span-1 flex justify-end items-center">
                            <button
                                onClick={() => {
                                    setSelectedAuthorSlug(author.slug);
                                    setActiveComponent("authorTransactions");
                                }}
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded"
                            >
                                View Transactions
                            </button>
                        </div>
                    </div>
                    <hr />
                </>
            ))}
        </div>
    );
};

export default Authors;
