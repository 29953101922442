// src/components/MainLayout.js
import React from "react";
import Header from "./Header";
import Footer from "./Footer";

const MainLayout = ({ children }) => {
    return (
        <div className="content min-h-screen flex flex-col bg-gray-800">
            <Header />
            <div className="p-4 bg-white flex-grow md:mx-16 lg:mx-32">
                {children}
            </div>
            <Footer />
        </div>
    );
};

export default MainLayout;
