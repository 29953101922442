// src/components/AuthorList.js
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAuthors } from "../services/authorService";

const AuthorList = () => {
    const [authors, setAuthors] = useState([]);

    useEffect(() => {
        getAuthors().then((data) => setAuthors(data));
    }, []);

    return (
        <ul>
            {authors.map((author) => (
                <li key={author.slug}>
                    <Link to={`/authors/${author.slug}`}>{author.name}</Link>
                </li>
            ))}
        </ul>
    );
};

export default AuthorList;
