import { db } from "../firebase-config";
import {
    collection,
    doc,
    updateDoc,
    addDoc,
    arrayUnion,
} from "firebase/firestore";
import {
    getBookByIdentifier,
    aggregateSalesData,
} from "../services/bookService";
import { addAuthorTransaction } from "../services/authorService";
import { format as formatDate } from "date-fns";
import Decimal from "decimal.js";
import { addStaffTransaction } from "../services/staffService";

export const matchesPatterns = (transactionDescription, trigger) => {
    const { patterns, mustMatchAll } = trigger;
    return mustMatchAll
        ? patterns.every((pattern) => transactionDescription.includes(pattern))
        : patterns.some((pattern) => transactionDescription.includes(pattern));
};

export const categorizeTransaction = (transaction, patterns) => {
    for (let pattern of patterns) {
        if (
            pattern.triggers.some((trigger) =>
                matchesPatterns(transaction["<Additional Info>"], trigger)
            )
        ) {
            // Prepare the base response object with default attributes
            const response = {
                category: pattern.category,
                description: pattern.description,
                found: true,
            };

            // If the category is 'Publishing Revenue', add 'salesChannel' to the response
            if (pattern.category === "Publishing Revenue") {
                response.salesChannel = pattern.salesChannel; // Assume salesChannel is defined in your pattern
            }

            return response;
        }
    }

    // Return found: false if no pattern matches
    return {
        found: false,
    };
};

export const updateAuthorAndBookRecords = async (
    convertedMarketData,
    bankTransaction,
    salesPeriod
) => {
    console.log(
        "Received convertedMarketData:",
        JSON.stringify(convertedMarketData, null, 2)
    );
    console.log(
        "Received bankTransaction:",
        JSON.stringify(bankTransaction, null, 2)
    );
    console.log("Received salesPeriod:", salesPeriod);

    for (const [salesChannel, formatsData] of Object.entries(
        convertedMarketData
    )) {
        console.log("Processing salesChannel:", salesChannel);
        console.log("formatsData:", JSON.stringify(formatsData, null, 2));

        for (const [format, transactions] of Object.entries(formatsData)) {
            for (const transaction of transactions) {
                const {
                    identifier,
                    netUnitsSold,
                    royalty,
                    containsReturns,
                    returnedCopies,
                    dateRange,
                } = transaction;
                const book = await getBookByIdentifier(identifier);
                if (!book) {
                    console.error("No book found for identifier:", identifier);
                    continue; // Skip if no book is found
                }

                const authorId = book.author.id; // Here we extract the author ID from the reference
                const dateString = formatDate(
                    new Date(bankTransaction.date),
                    "yyyy-MM-dd"
                );

                try {
                    const royaltyDecimal =
                        royalty > 0
                            ? (new Decimal(royalty) * book.author_royalty) / 100
                            : new Decimal(royalty);
                    let description = `${netUnitsSold} ${book.title} ${format} sold on ${bankTransaction.salesChannel} from ${salesPeriod}`;
                    if (containsReturns) {
                        const totalCopies = netUnitsSold + returnedCopies;
                        description = `${netUnitsSold} (${totalCopies} total, ${returnedCopies} returned) ${book.title} ${format} sold on ${bankTransaction.salesChannel} from ${salesPeriod}`;
                    }

                    // If dateRange is true, we use the end date for salesPeriod in the sales_data collection
                    const finalSalesPeriod = dateRange
                        ? salesPeriod.split(" to ")[1]
                        : salesPeriod;

                    console.log("sales Period: ", salesPeriod);

                    await addAuthorTransaction(authorId, {
                        date: dateString,
                        amount: `$${royaltyDecimal.toFixed(2)}`,
                        description: description,
                    });

                    if (book.staff_royalties && royalty > 0) {
                        const royaltyDecimal = new Decimal(royalty);
                        for (const [staffSlug, percentage] of Object.entries(
                            book.staff_royalties
                        )) {
                            const staffRoyaltyAmount = royaltyDecimal
                                .mul(percentage)
                                .div(100)
                                .toFixed(2);
                            await addStaffTransaction(staffSlug, {
                                date: dateString,
                                amount: staffRoyaltyAmount,
                                description: description,
                            });
                        }
                    }

                    await aggregateSalesData(book.id, [
                        {
                            netUnitsSold,
                            royalty: royaltyDecimal.toFixed(2),
                            format,
                            salesPeriod: finalSalesPeriod,
                        },
                    ]);
                } catch (error) {
                    console.error(
                        "Failed to update records for identifier:",
                        identifier,
                        error
                    );
                }
            }
        }
    }
};

// Function to confirm and lock the balance at the end of an accounting period
export const confirmBalance = async (authorId, newBalance) => {
    const authorRef = doc(db, "authors", authorId);
    await updateDoc(authorRef, {
        lastConfirmedBalance: {
            amount: newBalance,
            date: new Date().toISOString(),
        },
        currentBalance: newBalance,
        balanceHistory: arrayUnion({
            amount: newBalance,
            date: new Date().toISOString(),
        }),
    });
};
