// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import AuthorList from "./components/AuthorList";
import AuthorDetail from "./components/AuthorDetail";
import StaffList from "./components/StaffList";
import StaffDetail from "./components/StaffDetail";
import BookDetail from "./components/BookDetail";
import AccountingMain from "./components/AccountingMain";
import PrivateRoute from "./components/PrivateRoute";
import AddPatternForm from "./components/AddPatternForm";
import DbGui from "./components/DbGui";
import Test from "./components/Test";
import MainLayout from "./components/MainLayout";
import "./styles/main.css";

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route
                    path="/*"
                    element={
                        <PrivateRoute>
                            <MainLayout>
                                <Routes>
                                    <Route path="/" element={<Dashboard />} />
                                    <Route
                                        path="/authors"
                                        element={<AuthorList />}
                                    />
                                    <Route
                                        path="/staff"
                                        element={<StaffList />}
                                    />
                                    <Route
                                        path="/authors/:authorSlug"
                                        element={<AuthorDetail />}
                                    />
                                    <Route
                                        path="/staff/:staffSlug"
                                        element={<StaffDetail />}
                                    />
                                    <Route
                                        path="/books/:slug"
                                        element={<BookDetail />}
                                    />
                                    <Route
                                        path="/accounting/patterns/add"
                                        element={<AddPatternForm />}
                                    />
                                    <Route
                                        path="/accounting"
                                        element={<AccountingMain />}
                                    />
                                    <Route path="/db" element={<DbGui />} />
                                    <Route path="/test" element={<Test />} />
                                </Routes>
                            </MainLayout>
                        </PrivateRoute>
                    }
                />
            </Routes>
        </Router>
    );
}

export default App;
