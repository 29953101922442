// services/authorService.js

import { db } from "../firebase-config";
import {
    collection,
    query,
    where,
    getDoc,
    getDocs,
    doc,
    addDoc,
    updateDoc,
    deleteDoc,
    arrayUnion,
    writeBatch,
} from "firebase/firestore";
import Decimal from "decimal.js";
import { format } from "date-fns";

export async function getAuthors() {
    const authorsCol = collection(db, "authors");
    const authorSnapshot = await getDocs(authorsCol);
    const authorList = authorSnapshot.docs.map((doc) => ({
        id: doc.id, // Still useful for other operations
        name: doc.data().name,
        slug: doc.data().slug, // Ensure the slug is retrieved
    }));
    return authorList;
}

export const getAuthorDetails = async (slug) => {
    const q = query(collection(db, "authors"), where("slug", "==", slug));
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
        const docSnap = querySnapshot.docs[0]; // Assuming slugs are unique
        return { id: docSnap.id, ...docSnap.data() };
    } else {
        return null;
    }
};

export const addAuthor = async (authorData) => {
    const authorsRef = collection(db, "authors");
    return await addDoc(authorsRef, authorData);
};

export const updateAuthor = async (id, newFields) => {
    const authorDoc = doc(db, "authors", id);
    return await updateDoc(authorDoc, newFields);
};

export const deleteAuthor = async (id) => {
    const authorDoc = doc(db, "authors", id);
    return await deleteDoc(authorDoc);
};

export const updateAuthorTransactions = async (authorId, transactions) => {
    const authorRef = doc(db, "authors", authorId);
    const transactionsRef = collection(authorRef, "transactions");

    for (const transaction of transactions) {
        // Ensure amount is formatted as a string with a dollar sign
        const formattedAmount = `$${transaction.amount}`;
        // Ensure date is formatted as a string
        const formattedDate = format(new Date(transaction.date), "yyyy-MM-dd");

        await addDoc(transactionsRef, {
            ...transaction,
            amount: formattedAmount,
            date: formattedDate,
        });
    }
};

export const getTransactionsByAuthor = async (authorSlug) => {
    const authorRef = doc(db, "authors", authorSlug);
    const transactionsCol = collection(authorRef, "transactions");
    const snapshot = await getDocs(transactionsCol); // Assuming 'date' field exists and you want the newest first

    const transactions = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
    }));

    return transactions;
};

export const deleteAuthorTransaction = async (authorId, transactionId) => {
    const transactionRef = doc(
        collection(db, "authors", authorId, "transactions"),
        transactionId
    );

    try {
        await deleteDoc(transactionRef);
    } catch (error) {
        console.error(
            `Error deleting transaction ${transactionId} for author ${authorId}:`,
            error
        );
        throw new Error(
            `Error deleting transaction for author ${authorId}: ${error.message}`
        );
    }
};

export const addAuthorTransaction = async (authorId, transaction) => {
    const authorRef = doc(db, "authors", authorId);
    const transactionsRef = collection(authorRef, "transactions");

    try {
        await addDoc(transactionsRef, transaction);
    } catch (error) {
        console.error(
            `Error adding transaction for author ${authorId}:`,
            error
        );
        throw error;
    }
};

export const getAuthorsWithTransactions = async () => {
    const authors = [];
    const authorsSnapshot = await getDocs(collection(db, "authors"));

    for (const doc of authorsSnapshot.docs) {
        const authorData = { id: doc.id, ...doc.data() };
        const transactionsSnapshot = await getDocs(
            collection(db, `authors/${doc.id}/transactions`)
        );
        authorData.transactions = transactionsSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
        }));
        authors.push(authorData);
    }

    return authors;
};

export const updateBalanceHistory = async (authorId, newBalance, date) => {
    const authorRef = doc(db, "authors", authorId);
    return updateDoc(authorRef, {
        balanceHistory: arrayUnion({
            amount: newBalance,
            date: format(date, "MM/dd/yyyy"),
        }),
    });
};

export const processAuthorPayments = async (payments) => {
    const batch = writeBatch(db);
    const paymentDate = new Date();
    const monthName = paymentDate.toLocaleString("en-US", { month: "long" });

    payments.forEach((payment) => {
        const authorRef = doc(db, "authors", payment.authorId);
        const transaction = {
            date: paymentDate.toISOString().split("T")[0],
            amount: `-$${payment.paymentAmount}`,
            description: `${monthName} Royalty Payment`,
        };

        const transactionRef = collection(authorRef, "transactions");
        batch.set(doc(transactionRef), transaction);
    });

    try {
        await batch.commit();
    } catch (error) {
        console.error("Error processing payments: ", error);
    }
};
