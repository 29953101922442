// bankService.js

import { db } from "../firebase-config";
import {
    collection,
    doc,
    setDoc,
    query,
    where,
    getDocs,
    writeBatch,
} from "firebase/firestore";
import { formatDateAsString } from "../utils/helperUtils";
import Decimal from "decimal.js";

const loadExistingTransactionCounts = async (dateString) => {
    const transactionsRef = collection(db, "bankTransactions");
    const dateQuery = query(transactionsRef, where("date", "==", dateString));
    const snapshot = await getDocs(dateQuery);
    return snapshot.docs.length; // Get the count of existing documents
};

export const submitTransactions = async (transactions) => {
    const transactionCounts = {}; // Store counts of transactions per date

    for (const trans of transactions) {
        const transactionDate = new Date(trans["<Date>"]);
        const dateString = formatDateAsString(transactionDate, "YYYY-MM-DD");

        // Initialize or update transaction count for this date
        if (!(dateString in transactionCounts)) {
            transactionCounts[dateString] = await loadExistingTransactionCounts(
                dateString
            );
        }

        const withdrawalAmount = new Decimal(trans["<Withdrawal Amount>"] || 0);
        const depositAmount = new Decimal(trans["<Deposit Amount>"] || 0);
        const amount = withdrawalAmount.plus(depositAmount).toString();

        let docData = {
            date: transactionDate,
            category: trans.category,
            description: trans.description,
            bankDescription: trans["<Additional Info>"],
            usdAmount: amount,
            processed: [
                "Publishing Revenue",
                "Adjustments",
                "Book-Related Expenses",
            ].includes(trans.category)
                ? false
                : true,
        };
        // Conditionally add salesChannel if applicable
        if (trans.category === "Publishing Revenue" && trans.salesChannel) {
            docData.salesChannel = trans.salesChannel;
        }
        // Create a transaction ID with incrementing number
        const transactionId = `${dateString}-${transactionCounts[
            dateString
        ]++}`;

        try {
            const transactionDocRef = doc(
                db,
                "bankTransactions",
                transactionId
            );
            await setDoc(transactionDocRef, docData);
        } catch (error) {
            console.error(
                `Failed to add transaction for date ${dateString}:`,
                error
            );
            throw new Error(
                `Error adding transaction to database: ${error.message}`
            );
        }
    }
};

export const markBankTransactionsProcessed = async (transactionIds) => {
    const batch = writeBatch(db);
    transactionIds.forEach((transactionId) => {
        const transactionRef = doc(db, "bankTransactions", transactionId);
        batch.update(transactionRef, { processed: true });
    });
    try {
        await batch.commit();
    } catch (error) {
        console.error("Failed to mark transactions as processed:", error);
        throw error; // Rethrow to handle in caller if necessary
    }
};
