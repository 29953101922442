import React, { useState } from "react";
import AdjustmentForm from "./AdjustmentForm";
import { updateAuthorTransactions } from "../services/authorService";
import { markBankTransactionsProcessed } from "../services/bankService";
import { PulseLoader } from "react-spinners";
import Decimal from "decimal.js";

const AdjustmentProcessor = ({ transaction, removeProcessedTransactions }) => {
    const initialAmount = new Decimal(transaction.usdAmount || 0); // Ensure it's a valid number
    const [remainingAmount, setRemainingAmount] = useState(initialAmount);
    const [adjustments, setAdjustments] = useState([]);
    const [loading, setLoading] = useState(false);

    const addAdjustment = (author, amount, description) => {
        const newAdjustment = {
            author,
            amount: new Decimal(amount),
            description,
        };
        setAdjustments((prevAdjustments) => [
            ...prevAdjustments,
            newAdjustment,
        ]);
        setRemainingAmount((prev) =>
            new Decimal(prev).minus(new Decimal(amount))
        );
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!new Decimal(remainingAmount).equals(0)) {
            alert("Total amount does not match the specified value.");
            return;
        }

        try {
            setLoading(true);

            for (const adjustment of adjustments) {
                await updateAuthorTransactions(adjustment.author.id, [
                    {
                        amount: adjustment.amount.toFixed(2),
                        description: adjustment.description,
                        date: transaction.date.toISOString().split("T")[0],
                    },
                ]);
            }

            await markBankTransactionsProcessed([transaction.id]);
            await removeProcessedTransactions([transaction.id]);
            setLoading(false);
        } catch (error) {
            console.error("Failed to process adjustments:", error);
        }
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <PulseLoader color="#36d7b7" />
            </div>
        );
    }

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <p className="mt-4 text-lg font-semibold">
                    Remaining Amount: ${remainingAmount.toFixed(2)}
                </p>
                <ul className="mt-4 bg-white p-4 border rounded-md shadow-md">
                    {adjustments.map((adjustment, index) => (
                        <li key={index} className="mb-2 p-2 border rounded">
                            <p>
                                <strong>{adjustment.author.name}: </strong>
                                {adjustment.amount.toFixed(2)} -{" "}
                                {adjustment.description}
                            </p>
                        </li>
                    ))}
                </ul>
                {!remainingAmount.equals(new Decimal(0)) ? (
                    <AdjustmentForm addAdjustment={addAdjustment} />
                ) : (
                    <button
                        type="submit"
                        className="mt-4 px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600"
                    >
                        Finalize Adjustments
                    </button>
                )}
            </form>
        </div>
    );
};

export default AdjustmentProcessor;
