import React, { useState } from "react";
import { ref, uploadBytes } from "firebase/storage";
import { storage } from "../firebase-config";

const AccountingUpload = () => {
    const [file, setFile] = useState(null);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleUpload = async () => {
        if (!file) {
            alert("Please select a file first!");
            return;
        }

        const currentDate = new Date();
        currentDate.setMonth(currentDate.getMonth() - 1); // Move to the previous month
        const month = currentDate
            .toLocaleString("default", { month: "long" })
            .toLowerCase();
        const year = currentDate.getFullYear();
        const formattedFileName = `${month}-${year}.csv`;
        const storageRef = ref(storage, `bank-data/${formattedFileName}`);
        // const [fileExists, setFileExists] = useState(false);

        try {
            await uploadBytes(storageRef, file);
            alert("File uploaded successfully!");
        } catch (error) {
            console.error("Upload failed:", error);
            alert("Upload failed!");
        }
    };

    return (
        <div className="max-w-md mx-auto mt-10 shadow-lg p-6 bg-white rounded-lg">
            <h2 className="text-lg font-semibold text-center mb-4">
                Upload Bank CSV
            </h2>
            <div className="flex items-center justify-between">
                <input
                    type="file"
                    onChange={handleFileChange}
                    accept=".csv"
                    className="form-input rounded-md shadow-sm border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
                <button
                    onClick={handleUpload}
                    className="ml-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                    Upload CSV
                </button>
            </div>
        </div>
    );
};

export default AccountingUpload;
