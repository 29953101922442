import React from "react";

const CategoriesDropdown = ({ defaultValue, onChange }) => {
    const categories = [
        { id: "publishing-revenue", name: "Publishing Revenue" },
        { id: "non-publishing-revenue", name: "Non-Publishing Revenue" },
        { id: "adjustments", name: "Adjustments" },
        { id: "general-business-expenses", name: "General Business Expenses" },
        { id: "royalty-payments", name: "Royalty Payments" },
        { id: "staff-payments", name: "Staff Payments" },
    ];
    return (
        <select
            value={defaultValue}
            onChange={onChange}
            className="shadow border rounded py-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        >
            <option value="">Select a Category</option>
            {categories.map((category) => (
                <option key={category.id} value={category.name}>
                    {category.name}
                </option>
            ))}
        </select>
    );
};

export default CategoriesDropdown;
