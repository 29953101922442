// file: components/BookDropdown.js

import React, { useState, useEffect } from "react";
import { getAllBooks } from "../services/bookService";

const BookDropdown = ({ selectedBook, setSelectedBook }) => {
    const [books, setBooks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");

    useEffect(() => {
        // Fetch all books once the component mounts
        const fetchBooks = async () => {
            try {
                const booksList = await getAllBooks();
                setBooks(booksList);
            } catch (err) {
                setError("Failed to fetch books");
            } finally {
                setLoading(false);
            }
        };
        fetchBooks();
    }, []);

    const handleBookChange = (e) => {
        const selectedOption = e.target.options[e.target.selectedIndex];
        setSelectedBook({
            identifier: e.target.value,
            title: selectedOption.text,
        });
    };
    if (loading) return <p>Loading books...</p>;
    if (error) return <p>{error}</p>;

    return (
        <select
            value={selectedBook.identifier}
            onChange={handleBookChange}
            className="block p-2 border rounded-md mb-2"
        >
            <option value="" disabled>
                Select a book
            </option>
            {books.map((book) => (
                <option key={book.id} value={book.identifiers[0]}>
                    {book.title}
                </option>
            ))}
        </select>
    );
};

export default BookDropdown;
