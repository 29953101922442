import React, { useState, useEffect } from "react";
import {
    addAuthorTransaction,
    updateAuthorTransactions,
    deleteAuthorTransaction,
} from "../../services/authorService";

const TransactionForm = ({ authorId, transaction, onSave, onCancel }) => {
    const [formData, setFormData] = useState({
        date: transaction ? transaction.date : "",
        amount: transaction ? transaction.amount : "",
        description: transaction ? transaction.description : "",
    });

    useEffect(() => {
        if (transaction) {
            setFormData({
                date: transaction.date,
                amount: transaction.amount,
                description: transaction.description,
            });
        }
    }, [transaction]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (transaction) {
            await updateAuthorTransactions(authorId, transaction.id, formData);
        } else {
            await addAuthorTransaction(authorId, formData);
        }
        onSave();
    };

    const handleDelete = async () => {
        if (transaction) {
            await deleteAuthorTransaction(authorId, transaction.id);
        }
        onSave();
    };

    return (
        <form onSubmit={handleSubmit}>
            <input
                type="date"
                name="date"
                value={formData.date}
                onChange={handleChange}
                required
            />
            <input
                type="number"
                name="amount"
                value={formData.amount}
                onChange={handleChange}
                required
            />
            <input
                type="text"
                name="description"
                value={formData.description}
                onChange={handleChange}
                required
            />
            <button type="submit">Save</button>
            {transaction && (
                <button type="button" onClick={handleDelete}>
                    Delete
                </button>
            )}
            <button type="button" onClick={onCancel}>
                Cancel
            </button>
        </form>
    );
};

export default TransactionForm;
