// file: components/TransactionProcessor.js

import Decimal from "decimal.js";
import React, { useState } from "react";
import ManualSalesForm from "./ManualSalesForm";
import { updateAuthorAndBookRecords } from "../utils/transactionUtils";
import { markBankTransactionsProcessed } from "../services/bankService";
import { PulseLoader } from "react-spinners";

const TransactionProcessor = ({ transaction, removeProcessedTransactions }) => {
    const initialAmount = new Decimal(transaction.usdAmount || 0); // Ensure it's a valid number
    const [remainingAmount, setRemainingAmount] = useState(initialAmount);
    const [entries, setEntries] = useState([]);
    const [loading, setLoading] = useState(false);
    const [mostRecentMonth, setMostRecentMonth] = useState(
        new Date().toLocaleString("default", { month: "long" })
    );
    const [mostRecentYear, setMostRecentYear] = useState(
        new Date().getFullYear().toString()
    );
    const [mostRecentBeginMonth, setMostRecentBeginMonth] =
        useState(mostRecentMonth);
    const [mostRecentBeginYear, setMostRecentBeginYear] =
        useState(mostRecentYear);
    const [mostRecentEndMonth, setMostRecentEndMonth] =
        useState(mostRecentMonth);
    const [mostRecentEndYear, setMostRecentEndYear] = useState(mostRecentYear);
    const [mostRecentDateRange, setMostRecentDateRange] = useState(false);
    const [convertedMarketData, setConvertedMarketData] = useState({});

    const addEntry = (
        identifier,
        bookTitle,
        decimalAmount,
        copies,
        format,
        salesPeriod,
        containsReturns = false,
        returnedCopies = 0,
        dateRange = false,
        beginMonth,
        beginYear,
        endMonth,
        endYear
    ) => {
        const newEntry = {
            bookTitle,
            identifier,
            amount: decimalAmount,
            copies,
            format,
            salesPeriod,
            containsReturns,
            returnedCopies,
            dateRange,
        };
        setEntries((prevEntries) => [...prevEntries, newEntry]);
        setRemainingAmount((prev) => new Decimal(prev).minus(decimalAmount));

        if (dateRange) {
            setMostRecentBeginMonth(beginMonth);
            setMostRecentBeginYear(beginYear);
            setMostRecentEndMonth(endMonth);
            setMostRecentEndYear(endYear);
        } else {
            setMostRecentMonth(beginMonth);
            setMostRecentYear(beginYear);
        }
        setMostRecentDateRange(dateRange);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!new Decimal(remainingAmount).equals(0)) {
            alert("Total amount does not match the specified value.");
            return;
        }

        // Prepare data for update, handling varying sales periods
        const convertedMarketData = {
            [transaction.salesChannel]: {},
        };

        entries.forEach((entry) => {
            if (!convertedMarketData[transaction.salesChannel][entry.format]) {
                convertedMarketData[transaction.salesChannel][entry.format] =
                    [];
            }

            convertedMarketData[transaction.salesChannel][entry.format].push({
                identifier: entry.identifier,
                netUnitsSold: entry.copies,
                royalty: entry.amount.toFixed(2),
                salesPeriod: entry.salesPeriod,
                containsReturns: entry.containsReturns,
                returnedCopies: entry.returnedCopies,
                dateRange: entry.dateRange,
            });
        });

        // Use the first entry's salesPeriod as the overall salesPeriod
        const salesPeriod = entries[0]?.salesPeriod || "";

        console.log(
            "convertedMarketData structure:",
            JSON.stringify(convertedMarketData, null, 2)
        );
        console.log("bankTransaction:", JSON.stringify(transaction, null, 2));
        console.log("salesPeriod:", salesPeriod);

        try {
            setLoading(true);
            await updateAuthorAndBookRecords(
                convertedMarketData,
                transaction,
                salesPeriod
            );
            await markBankTransactionsProcessed([transaction.id]);
            await removeProcessedTransactions([transaction.id]);
            setLoading(false);
        } catch (error) {
            console.error("Failed to process transactions:", error);
        }
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <PulseLoader color="#36d7b7" />
            </div>
        );
    }

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <p className="mt-4 text-lg font-semibold">
                    Remaining Amount: ${remainingAmount.toFixed(2)}
                </p>
                <ul className="mt-4 bg-white p-4 border rounded-md shadow-md">
                    {entries.map((entry, index) => (
                        <li key={index} className="mb-2 p-2 border rounded">
                            <p>
                                <strong>{entry.bookTitle}: </strong>
                                {entry.copies} {entry.format} ( $
                                {entry.amount.toFixed(2)}) [{entry.salesPeriod}]
                                {entry.containsReturns &&
                                    ` (Total: ${
                                        entry.copies + entry.returnedCopies
                                    }, Returned: ${entry.returnedCopies})`}
                            </p>
                        </li>
                    ))}
                </ul>
                {!remainingAmount.equals(new Decimal(0)) ? (
                    <ManualSalesForm
                        addEntry={addEntry}
                        mostRecentMonth={mostRecentMonth}
                        mostRecentYear={mostRecentYear}
                        mostRecentBeginMonth={mostRecentBeginMonth}
                        mostRecentBeginYear={mostRecentBeginYear}
                        mostRecentEndMonth={mostRecentEndMonth}
                        mostRecentEndYear={mostRecentEndYear}
                        mostRecentDateRange={mostRecentDateRange}
                    />
                ) : (
                    <button
                        type="submit"
                        className="mt-4 px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600"
                    >
                        Finalize Submission
                    </button>
                )}
            </form>
        </div>
    );
};

export default TransactionProcessor;
