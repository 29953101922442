// TransactionItem.js

import React from "react";

const TransactionItem = ({ transaction, onSelect }) => {
    return (
        <li
            onClick={() => onSelect(transaction)}
            className="cursor-pointer hover:bg-gray-200 rounded-md p-2"
        >
            {transaction.date.toDateString()} - ${transaction.usdAmount} -{" "}
            {transaction.description}
        </li>
    );
};

export default TransactionItem;
