// file: components/ManualSalesForm.js

import Decimal from "decimal.js";
import React, { useState, useEffect } from "react";
import BookDropdown from "./BookDropdown";
import { getMonths, getYears, formatSalesPeriod } from "../utils/helperUtils";

const ManualSalesForm = ({
    addEntry,
    mostRecentMonth,
    mostRecentYear,
    mostRecentBeginMonth,
    mostRecentBeginYear,
    mostRecentEndMonth,
    mostRecentEndYear,
    mostRecentDateRange,
}) => {
    const months = getMonths();
    const years = getYears();

    const [selectedBook, setSelectedBook] = useState({
        identifier: "",
        title: "",
    });
    const [amount, setAmount] = useState("");
    const [copies, setCopies] = useState("");
    const [format, setFormat] = useState("ebook");
    const [selectedMonth, setSelectedMonth] = useState(mostRecentMonth);
    const [selectedYear, setSelectedYear] = useState(mostRecentYear);
    const [containsReturns, setContainsReturns] = useState(false);
    const [returnedCopies, setReturnedCopies] = useState("");
    const [dateRange, setDateRange] = useState(mostRecentDateRange);
    const [beginMonth, setBeginMonth] = useState(mostRecentBeginMonth);
    const [beginYear, setBeginYear] = useState(mostRecentBeginYear);
    const [endMonth, setEndMonth] = useState(mostRecentEndMonth);
    const [endYear, setEndYear] = useState(mostRecentEndYear);

    useEffect(() => {
        setSelectedMonth(mostRecentMonth);
        setSelectedYear(mostRecentYear);
        setBeginMonth(mostRecentBeginMonth);
        setBeginYear(mostRecentBeginYear);
        setEndMonth(mostRecentEndMonth);
        setEndYear(mostRecentEndYear);
        setDateRange(mostRecentDateRange);
    }, [
        mostRecentMonth,
        mostRecentYear,
        mostRecentBeginMonth,
        mostRecentBeginYear,
        mostRecentEndMonth,
        mostRecentEndYear,
        mostRecentDateRange,
    ]);

    const handleAddEntryClick = (e) => {
        e.preventDefault();
        if (!selectedBook.identifier || !amount || !copies) {
            alert("Please fill in all required fields.");
            return;
        }

        const salesPeriod = dateRange
            ? `${formatSalesPeriod(
                  beginMonth,
                  beginYear
              )} to ${formatSalesPeriod(endMonth, endYear)}`
            : `${formatSalesPeriod(selectedMonth, selectedYear)}`;
        const decimalAmount = new Decimal(amount || 0);

        addEntry(
            selectedBook.identifier,
            selectedBook.title,
            decimalAmount,
            parseInt(copies, 10),
            format,
            salesPeriod,
            containsReturns,
            parseInt(returnedCopies, 10) || 0,
            dateRange,
            beginMonth,
            beginYear,
            endMonth,
            endYear
        );
        resetFormFields();
    };

    const resetFormFields = () => {
        setSelectedBook({ identifier: "", title: "" });
        setAmount("");
        setCopies("");
        setFormat("ebook");
        setSelectedMonth(mostRecentMonth);
        setSelectedYear(mostRecentYear);
        setContainsReturns(false);
        setReturnedCopies("");
        setDateRange(mostRecentDateRange);
        setBeginMonth(mostRecentBeginMonth);
        setBeginYear(mostRecentBeginYear);
        setEndMonth(mostRecentEndMonth);
        setEndYear(mostRecentEndYear);
    };

    return (
        <div className="mt-4 p-4 bg-gray-100 rounded-md">
            <BookDropdown
                selectedBook={selectedBook}
                setSelectedBook={setSelectedBook}
                className="block p-2 border rounded-md mb-2"
            />
            <label className="block">
                Amount:
                <input
                    type="number"
                    required
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    className="block p-2 border rounded-md mb-2"
                />
            </label>
            <label className="block">
                Copies:
                <input
                    type="number"
                    required
                    value={copies}
                    onChange={(e) => setCopies(e.target.value)}
                    className="block p-2 border rounded-md mb-2"
                />
            </label>
            <label className="block mt-4">
                <input
                    type="checkbox"
                    checked={containsReturns}
                    onChange={(e) => setContainsReturns(e.target.checked)}
                    className="mr-2"
                />
                Contains Returns
            </label>
            {containsReturns && (
                <label className="block mt-2">
                    Returned Copies:
                    <input
                        type="number"
                        value={returnedCopies}
                        onChange={(e) => setReturnedCopies(e.target.value)}
                        className="block p-2 border rounded-md mb-2"
                    />
                </label>
            )}
            <label className="block">
                Format:
                <select
                    value={format}
                    onChange={(e) => setFormat(e.target.value)}
                    className="block p-2 border rounded-md mb-2"
                >
                    <option value="audiobook">Audiobook</option>
                    <option value="ebook">Ebook</option>
                    <option value="hardcover">Hardcover</option>
                    <option value="paperback">Paperback</option>
                </select>
            </label>
            <label className="block mt-4">
                <input
                    type="checkbox"
                    checked={dateRange}
                    onChange={(e) => setDateRange(e.target.checked)}
                    className="mr-2"
                />
                Date Range
            </label>
            {dateRange ? (
                <div className="flex gap-4 mt-2">
                    <div>
                        <label className="block">Begin Month:</label>
                        <select
                            value={beginMonth}
                            onChange={(e) => setBeginMonth(e.target.value)}
                            className="block w-full p-2 border rounded-md"
                        >
                            {months.map((month, index) => (
                                <option key={index} value={month}>
                                    {month}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label className="block">Begin Year:</label>
                        <select
                            value={beginYear}
                            onChange={(e) => setBeginYear(e.target.value)}
                            className="block w-full p-2 border rounded-md"
                        >
                            {years.map((year) => (
                                <option key={year} value={year}>
                                    {year}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label className="block">End Month:</label>
                        <select
                            value={endMonth}
                            onChange={(e) => setEndMonth(e.target.value)}
                            className="block w-full p-2 border rounded-md"
                        >
                            {months.map((month, index) => (
                                <option key={index} value={month}>
                                    {month}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label className="block">End Year:</label>
                        <select
                            value={endYear}
                            onChange={(e) => setEndYear(e.target.value)}
                            className="block w-full p-2 border rounded-md"
                        >
                            {years.map((year) => (
                                <option key={year} value={year}>
                                    {year}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            ) : (
                <div className="flex gap-4">
                    <div>
                        <label className="block">Month:</label>
                        <select
                            value={selectedMonth}
                            onChange={(e) => setSelectedMonth(e.target.value)}
                            className="block w-full mt-1 p-2 border rounded-md"
                        >
                            {months.map((month, index) => (
                                <option key={index} value={month}>
                                    {month}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label className="block">Year:</label>
                        <select
                            value={selectedYear}
                            onChange={(e) => setSelectedYear(e.target.value)}
                            className="block w-full mt-1 p-2 border rounded-md"
                        >
                            {years.map((year) => (
                                <option key={year} value={year}>
                                    {year}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            )}
            <button
                onClick={handleAddEntryClick}
                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
            >
                Add Entry
            </button>
        </div>
    );
};

export default ManualSalesForm;
