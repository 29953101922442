import React, { useState } from "react";
import Authors from "./dbGuiFiles/Authors";
import AuthorTransactions from "./dbGuiFiles/AuthorTransactions";
import StaffMembers from "./dbGuiFiles/StaffMembers";
import StaffTransactions from "./dbGuiFiles/StaffTransactions";

const DbGui = () => {
    const [activeComponent, setActiveComponent] = useState("home");
    const [selectedAuthorSlug, setSelectedAuthorSlug] = useState("");
    const [selectedStaffSlug, setSelectedStaffSlug] = useState("");

    const renderComponent = () => {
        switch (activeComponent) {
            case "authors":
                return (
                    <Authors
                        setActiveComponent={setActiveComponent}
                        setSelectedAuthorSlug={setSelectedAuthorSlug}
                    />
                );
            case "authorTransactions":
                return (
                    <AuthorTransactions
                        setActiveComponent={setActiveComponent}
                        authorSlug={selectedAuthorSlug}
                    />
                );
            case "staff":
                return (
                    <StaffMembers
                        setActiveComponent={setActiveComponent}
                        setSelectedStaffSlug={setSelectedStaffSlug}
                    />
                );
            case "staffTransactions":
                return (
                    <StaffTransactions
                        setActiveComponent={setActiveComponent}
                        staffSlug={selectedStaffSlug}
                    />
                );
            default:
                return <div>Welcome to the DB Dashboard</div>;
        }
    };

    return (
        <div className="min-h-screen bg-gray-100 p-4">
            <div className="flex space-x-4 mb-4">
                <button
                    onClick={() => setActiveComponent("home")}
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
                >
                    Home
                </button>
                <button
                    onClick={() => setActiveComponent("authors")}
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
                >
                    Authors
                </button>

                <button
                    onClick={() => setActiveComponent("staff")}
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
                >
                    Staff Members
                </button>
            </div>
            {renderComponent()}
        </div>
    );
};

export default DbGui;
