// src/components/Footer.js
import React from "react";

const Footer = () => {
    return (
        <footer className="bg-gray-800 text-white text-center p-4">
            <div className="container mx-auto">
                © {new Date().getFullYear()} Berwick Court Publishing Co. All
                rights reserved.
            </div>
        </footer>
    );
};

export default Footer;
