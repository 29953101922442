import { useEffect } from "react";
import { functions } from "../firebase-config"; // Import functions from your Firebase config
import { httpsCallable } from "firebase/functions"; // Import httpsCallable

const TestPdfGeneration = () => {
    const authorId = "matt-balson";
    const paymentAmount = "0";
    const authorArray = [{ authorId, paymentAmount }];
    const generatePdfs = async () => {
        try {
            const response = await fetch(
                "https://us-central1-bcpc-9b0b0.cloudfunctions.net/generateAuthorStatements",

                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ authors: authorArray }),
                }
            );

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            console.log("PDFs generated:", result);
            alert("PDFs generated successfully! Check your Firebase Storage.");
        } catch (error) {
            console.error("Error generating PDFs:", error);
            alert("Failed to generate PDFs.");
        }
    };

    return (
        <div>
            <button onClick={generatePdfs}>Generate PDFs</button>
        </div>
    );
};

export default TestPdfGeneration;
