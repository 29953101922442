import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getStaffMembers } from "../services/staffService";

const StaffList = () => {
    const [staffMembers, setStaffMembers] = useState([]);

    useEffect(() => {
        getStaffMembers().then((data) => setStaffMembers(data));
    }, []);

    return (
        <ul>
            {staffMembers.map((staff) => (
                <li key={staff.slug}>
                    <Link to={`/staff/${staff.slug}`}>{staff.name}</Link>
                </li>
            ))}
        </ul>
    );
};

export default StaffList;
