import React, { useEffect, useState } from "react";
import {
    getAuthorsWithTransactions,
    updateBalanceHistory,
    processAuthorPayments,
} from "../services/authorService";
import { calculateWorkingBalance } from "../utils/authorUtils";
import { calculateStaffWorkingBalance } from "../utils/staffUtils";
import {
    getStaffMembers,
    updateStaffBalanceHistory,
    processStaffPayments,
} from "../services/staffService";
import { PulseLoader } from "react-spinners";
import PdfGeneration from "./PdfGeneration";

const ReviewBalances = () => {
    const [authors, setAuthors] = useState([]);
    const [staffMembers, setStaffMembers] = useState([]); // For all staff members
    const [loading, setLoading] = useState(false);
    const [selectedForPayment, setSelectedForPayment] = useState({});
    const [balancesReviewed, setBalancesReviewed] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const fetchedAuthors = await getAuthorsWithTransactions();
            for (const author of fetchedAuthors) {
                author.balance = await calculateWorkingBalance(author);
            }
            setAuthors(fetchedAuthors);

            const fetchedStaffMembers = await getStaffMembers();
            for (const staff of fetchedStaffMembers) {
                staff.balance = await calculateStaffWorkingBalance(staff);
            }
            setStaffMembers(fetchedStaffMembers);

            const initialSelections = fetchedAuthors.reduce(
                (acc, author) => ({
                    ...acc,
                    [author.id]: author.balance > 10,
                }),
                {}
            );

            fetchedStaffMembers.forEach((staff) => {
                if (staff.balance > 10) {
                    initialSelections[staff.id] = true;
                }
            });

            setSelectedForPayment(initialSelections);
            setLoading(false);
        };

        fetchData();
    }, []);

    const handleCheckboxChange = (id) => {
        setSelectedForPayment((prev) => ({
            ...prev,
            [id]: !prev[id],
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        // Update balance history for all authors
        const today = new Date();
        const firstOfTheMonth = new Date(
            today.getFullYear(),
            today.getMonth(),
            1
        );

        const balanceUpdates = authors.map((author) =>
            updateBalanceHistory(author.id, author.balance, firstOfTheMonth)
        );
        await Promise.all(balanceUpdates);

        // Update balance history for all staff members
        const staffBalanceUpdates = staffMembers.map((staff) =>
            updateStaffBalanceHistory(staff.id, staff.balance, firstOfTheMonth)
        );
        await Promise.all(staffBalanceUpdates);

        // Create payment transactions for selected authors
        const paymentAuthors = authors.map((author) => {
            const amount = selectedForPayment[author.id] ? author.balance : 0;
            return { authorId: author.id, paymentAmount: amount };
        });

        // Add staff members' payment transactions if selected
        const paymentStaff = staffMembers.map((staff) => {
            const amount = selectedForPayment[staff.id] ? staff.balance : 0;
            return { staffId: staff.id, paymentAmount: amount };
        });

        await processAuthorPayments(
            paymentAuthors.filter((author) => author.paymentAmount > 0)
        );

        // Process payment for staff members if selected
        await processStaffPayments(
            paymentStaff.filter((staff) => staff.paymentAmount > 0)
        );

        setLoading(false);
        setBalancesReviewed(true);
        alert("All balances updated and payments processed.");
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <PulseLoader color="#36d7b7" />
            </div>
        );
    }

    return (
        <div>
            {balancesReviewed ? (
                <PdfGeneration
                    setLoading={setLoading}
                    authorArray={authors.map((author) => ({
                        authorId: author.id,
                        paymentAmount: selectedForPayment[author.id]
                            ? author.balance
                            : 0,
                    }))}
                />
            ) : (
                <form onSubmit={handleSubmit}>
                    {authors.map((author) => (
                        <div key={author.id}>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={
                                        selectedForPayment[author.id] || false
                                    }
                                    onChange={() =>
                                        handleCheckboxChange(author.id)
                                    }
                                />
                                &nbsp; {author.name}: ${author.balance}
                            </label>
                        </div>
                    ))}
                    {staffMembers.map((staff) => (
                        <div key={staff.id}>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={
                                        selectedForPayment[staff.id] || false
                                    }
                                    onChange={() =>
                                        handleCheckboxChange(staff.id)
                                    }
                                />
                                &nbsp; {staff.name}: ${staff.balance}
                            </label>
                        </div>
                    ))}
                    <button
                        type="submit"
                        className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    >
                        Submit All Changes
                    </button>
                </form>
            )}
        </div>
    );
};

export default ReviewBalances;
