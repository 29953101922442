import { useEffect } from "react";
import { functions } from "../firebase-config";
import { httpsCallable } from "firebase/functions";

const PdfGeneration = ({ setLoading, authorArray }) => {
    const generatePdfs = async () => {
        try {
            setLoading(true);
            const response = await fetch(
                "https://us-central1-bcpc-9b0b0.cloudfunctions.net/generateAuthorStatements",

                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ authors: authorArray }),
                }
            );

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            console.log("PDFs generated:", result);
            alert("PDFs generated successfully! Check your Firebase Storage.");
            setLoading(false);
        } catch (error) {
            console.error("Error generating PDFs:", error);
            alert("Failed to generate PDFs.");
        }
    };

    return (
        <div>
            <button onClick={generatePdfs}>Generate PDFs</button>
        </div>
    );
};

export default PdfGeneration;
