// src/components/Header.js
import React from "react";
import { Link } from "react-router-dom";
import logo from "../images/bcpc-logo-2024.png";

const Header = () => {
    return (
        <header className="bg-gray-800 text-white p-4">
            <nav className="flex justify-around">
                <div className="text-white px-4 py-2 hover:bg-gray-700 rounded">
                    <Link to="/">Home</Link>
                </div>
                <div className="text-white px-4 py-2 hover:bg-gray-700 rounded">
                    <Link to="/accounting">Accounting</Link>
                </div>
                <div className="text-white px-4 py-2 hover:bg-gray-700 rounded">
                    <Link to="/reports">Reports</Link>
                </div>
                <div className="text-white px-4 py-2 hover:bg-gray-700 rounded">
                    <Link to="/db">Database</Link>
                </div>
            </nav>
            <div className="container mx-auto flex justify-center items-center">
                <div className="mx-auto flex flex-col items-center xs:flex-row justify-center py-8 max-w-screen-md">
                    <img src={logo} alt="Company Logo" className="h-16" />
                    <div className="text-xl pl-6 pt-3">
                        Berwick Court Publishing Co.
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
