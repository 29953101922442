import React, { useState, useEffect } from "react";
import { getAuthors } from "../services/authorService"; // Make sure to create this service to fetch authors from Firestore

const AuthorDropdown = ({ selectedAuthor, setSelectedAuthor }) => {
    const [authors, setAuthors] = useState([]);

    useEffect(() => {
        const fetchAuthors = async () => {
            const authorList = await getAuthors();
            setAuthors(authorList);
        };

        fetchAuthors();
    }, []);

    return (
        <select
            value={selectedAuthor.id}
            onChange={(e) =>
                setSelectedAuthor(
                    authors.find((author) => author.id === e.target.value)
                )
            }
            className="block p-2 border rounded-md mb-2"
        >
            <option value="" disabled>
                Select an Author
            </option>
            {authors.map((author) => (
                <option key={author.id} value={author.id}>
                    {author.name}
                </option>
            ))}
        </select>
    );
};

export default AuthorDropdown;
