import React from "react";

const ProgressBar = ({ currentStep, maxSteps }) => {
    const percentage = ((currentStep + 1) / maxSteps) * 100;

    return (
        <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700">
            <div
                className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"
                style={{ width: `${percentage}%` }}
            >
                Step {currentStep + 1} of {maxSteps}
            </div>
        </div>
    );
};

export default ProgressBar;
