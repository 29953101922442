import { getTransactionsByAuthor } from "../services/authorService";
import Decimal from "decimal.js";

export const calculateWorkingBalance = async (author) => {
    if (author) {
        const mostRecentBalance =
            author.balanceHistory[author.balanceHistory.length - 1];
        const mostRecentBalanceDate = new Date(mostRecentBalance.date);
        const transactions = await getTransactionsByAuthor(author.id);
        const transactionsSinceLastConfirm = transactions.filter(
            (transaction) => new Date(transaction.date) >= mostRecentBalanceDate
        );
        const updatedBalance = transactionsSinceLastConfirm.reduce(
            (sum, transaction) =>
                sum.add(new Decimal(transaction.amount.replace("$", ""))), // Assuming the amount string starts with '$'
            new Decimal(mostRecentBalance.amount)
        );
        return updatedBalance.toFixed(2); // This balance is used for working calculations but not stored until confirmed
    } else {
        throw new Error("Author not found.");
    }
};
