import {
    collection,
    getDocs,
    doc,
    getDoc,
    updateDoc,
    deleteDoc,
    addDoc,
} from "firebase/firestore";
import { db } from "../firebase-config";
import { format } from "date-fns";

const staffCollection = collection(db, "staff");

export const getStaffMembers = async () => {
    const snapshot = await getDocs(staffCollection);
    const staffMembers = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
    }));
    return staffMembers;
};

export const getTransactionsByStaff = async (staffSlug) => {
    const transactionsCollection = collection(
        db,
        `staff/${staffSlug}/transactions`
    );
    const snapshot = await getDocs(transactionsCollection);
    const transactions = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
    }));
    return transactions;
};

export const addStaffTransaction = async (staffSlug, transaction) => {
    const transactionsCollection = collection(
        db,
        `staff/${staffSlug}/transactions`
    );
    await addDoc(transactionsCollection, transaction);
};

export const updateStaffTransaction = async (
    staffSlug,
    transactionId,
    transaction
) => {
    const transactionDoc = doc(
        db,
        `staff/${staffSlug}/transactions`,
        transactionId
    );
    await updateDoc(transactionDoc, transaction);
};

export const deleteStaffTransaction = async (staffSlug, transactionId) => {
    const transactionDoc = doc(
        db,
        `staff/${staffSlug}/transactions`,
        transactionId
    );
    await deleteDoc(transactionDoc);
};

export const getStaffMember = async (staffSlug) => {
    const staffDoc = doc(staffCollection, staffSlug);
    const snapshot = await getDoc(staffDoc);
    return snapshot.exists() ? { id: snapshot.id, ...snapshot.data() } : null;
};

export const updateStaffBalanceHistory = async (staffId, balance, date) => {
    const staffDoc = doc(staffCollection, staffId);
    const snapshot = await getDoc(staffDoc);
    if (snapshot.exists()) {
        const staffData = snapshot.data();
        const balanceHistory = staffData.balanceHistory || [];
        await updateDoc(staffDoc, {
            balanceHistory: [
                ...balanceHistory,
                {
                    date: format(date, "MM/dd/yyyy"),
                    amount: balance.toString(),
                },
            ],
        });
    } else {
        throw new Error(`Staff member with ID ${staffId} does not exist.`);
    }
};

export const processStaffPayments = async (payments) => {
    for (const payment of payments) {
        const staffDoc = doc(staffCollection, payment.staffId);
        const transactionsCollection = collection(staffDoc, "transactions");
        const paymentDate = new Date();
        const monthName = paymentDate.toLocaleString("en-US", {
            month: "long",
        }); // Correct method to get the month's name
        await addDoc(transactionsCollection, {
            amount: `-$${payment.paymentAmount.toString()}`,
            date: paymentDate.toISOString().split("T")[0], // Ensures MM/DD/YYYY format
            description: `${monthName} Royalty Payment`,
        });
    }
};
