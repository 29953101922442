// src/components/Dashboard.js
import React from "react";
import { Link } from "react-router-dom";
import BookList from "./BookList";
import ReportGenerator from "./ReportGenerator";
import shakespeare from "../images/shakespeare.png";
import reporter from "../images/reporter.webp";

const Dashboard = () => {
    return (
        <div className="dashboard">
            <h1 className="text-2xl font-bold text-center mb-4">
                Admin Dashboard
            </h1>
            <div className="grid grid-cols-3 gap-4">
                <div className="col-span-1">
                    <Link to="/authors">
                        <h2>Authors</h2>
                        <div className="h-12">
                            <img
                                src={shakespeare}
                                alt="Author"
                                className="mx-auto 
                            rounded-full object-contain size-28"
                            />
                        </div>
                    </Link>
                </div>
                <div className="col-span-1">
                    <Link to="/staff">
                        <h2>Staff</h2>
                        <div className="h-12">
                            <img
                                src={reporter}
                                alt="Staff"
                                className="mx-auto 
                            rounded-full object-contain size-28"
                            />
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
