import React, { useState, useEffect } from "react";
import {
    getTransactionsByStaff,
    addStaffTransaction,
    updateStaffTransaction,
    deleteStaffTransaction,
} from "../../services/staffService";
import SortableTable from "../SortableTable";
import TransactionForm from "./TransactionForm";

const StaffTransactions = ({ staffSlug }) => {
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [editingTransaction, setEditingTransaction] = useState(null);

    useEffect(() => {
        fetchTransactions();
    }, [staffSlug]);

    const fetchTransactions = async () => {
        setLoading(true);
        try {
            const fetchedTransactions = await getTransactionsByStaff(staffSlug);
            setTransactions(fetchedTransactions);
            setLoading(false);
        } catch (err) {
            setError("Failed to fetch transactions: " + err.message);
            setLoading(false);
        }
    };

    const handleAddTransaction = async (transactionData) => {
        try {
            await addStaffTransaction(staffSlug, transactionData);
            fetchTransactions(); // Refresh list after adding
        } catch (error) {
            console.error("Error adding transaction:", error);
            setError("Error adding transaction: " + error.message);
        }
    };

    const handleUpdateTransaction = async (id, transactionData) => {
        try {
            await updateStaffTransaction(staffSlug, id, transactionData);
            fetchTransactions(); // Refresh list after update
        } catch (error) {
            console.error("Error updating transaction:", error);
            setError("Error updating transaction: " + error.message);
        }
    };

    const handleDeleteTransaction = async (id) => {
        try {
            await deleteStaffTransaction(staffSlug, id);
            fetchTransactions(); // Refresh list after delete
        } catch (error) {
            console.error("Error deleting transaction:", error);
            setError("Error deleting transaction: " + error.message);
        }
    };

    const handleDeleteSelected = async (selectedIds) => {
        try {
            await Promise.all(
                selectedIds.map((id) => deleteStaffTransaction(staffSlug, id))
            );
            fetchTransactions(); // Refresh the list after deletions
        } catch (error) {
            console.error("Error deleting selected transactions:", error);
            setError("Error deleting selected transactions: " + error.message);
        }
    };

    const columns = [
        { key: "date", label: "Date", type: "date" },
        { key: "amount", label: "Amount" },
        { key: "description", label: "Description" },
        {
            key: "actions",
            label: "Actions",
            render: (row) => (
                <>
                    <button onClick={() => setEditingTransaction(row)}>
                        Edit
                    </button>
                    <button onClick={() => handleDeleteTransaction(row.id)}>
                        Delete
                    </button>
                </>
            ),
        },
    ];

    if (loading) return <div>Loading transactions...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div>
            <h3>Transactions for {staffSlug}</h3>
            {editingTransaction && (
                <TransactionForm
                    initialData={editingTransaction}
                    onSubmit={(data) => {
                        handleUpdateTransaction(editingTransaction.id, data);
                        setEditingTransaction(null);
                    }}
                    onCancel={() => setEditingTransaction(null)}
                />
            )}
            {!editingTransaction && (
                <>
                    <TransactionForm onSubmit={handleAddTransaction} />
                    <SortableTable
                        columns={columns}
                        data={transactions}
                        defaultSortField="date"
                        onDeleteSelected={handleDeleteSelected}
                    />
                </>
            )}
        </div>
    );
};

export default StaffTransactions;
