import React, { useState } from "react";
import AuthorDropdown from "./AuthorDropdown";
import { getMonths, getYears } from "../utils/helperUtils";

const AdjustmentForm = ({ addAdjustment }) => {
    const [selectedAuthor, setSelectedAuthor] = useState({
        id: "",
        name: "",
    });
    const [amount, setAmount] = useState("");
    const [description, setDescription] = useState("");

    const handleAddAdjustmentClick = (e) => {
        e.preventDefault();
        if (!selectedAuthor.id || !amount || !description) {
            alert("Please fill in all fields.");
            return;
        }

        addAdjustment(selectedAuthor, amount, description);
        resetFormFields();
    };

    const resetFormFields = () => {
        setSelectedAuthor({ id: "", name: "" });
        setAmount("");
        setDescription("");
    };

    return (
        <div className="mt-4 p-4 bg-gray-100 rounded-md">
            <AuthorDropdown
                selectedAuthor={selectedAuthor}
                setSelectedAuthor={setSelectedAuthor}
                className="block p-2 border rounded-md mb-2"
            />
            <label className="block">
                Amount:
                <input
                    type="number"
                    required
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    className="block p-2 border rounded-md mb-2"
                />
            </label>
            <label className="block">
                Description:
                <input
                    type="text"
                    required
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="block p-2 border rounded-md mb-2"
                />
            </label>
            <button
                onClick={handleAddAdjustmentClick}
                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
            >
                Add Adjustment
            </button>
        </div>
    );
};

export default AdjustmentForm;
