import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
    getAuthorDetails,
    getTransactionsByAuthor,
} from "../services/authorService";
import { getBooksByAuthorSlug } from "../services/bookService";
import TransactionsTable from "./TransactionsTable";
import { Link } from "react-router-dom";
import { calculateWorkingBalance } from "../utils/authorUtils";

const AuthorDetail = () => {
    const { authorSlug } = useParams();
    const [author, setAuthor] = useState(null);
    const [books, setBooks] = useState([]);
    const [balance, setBalance] = useState("Calculating..."); // Default text or loading state
    const [transactions, setTransactions] = useState([]);

    useEffect(() => {
        if (authorSlug) {
            getAuthorDetails(authorSlug).then((authorData) => {
                setAuthor(authorData);
                if (authorData) {
                    getBooksByAuthorSlug(authorSlug).then(setBooks);
                    calculateWorkingBalance(authorData).then(setBalance);
                    getTransactionsByAuthor(authorSlug).then(setTransactions);
                }
            });
        }
    }, [authorSlug]);

    if (!author) {
        return <div>Loading author details...</div>;
    }
    return (
        <div>
            <h2>Books by {author.name}</h2>
            <div className="grid grid-flow-col justify-around">
                {books.map((book) => (
                    <Link to={`/books/${book.slug}`}>
                        <img
                            src={`https://bcpc.vercel.app/_next/image?url=%2Fimages%2F${book.slug}.jpg&w=256&q=75`}
                            alt={`Cover for ${book.name}`}
                            width={128}
                        />
                    </Link>
                ))}
            </div>
            <hr className="my-12 h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-neutral-500 to-transparent opacity-25 dark:via-neutral-400" />
            <h2>Balance: ${balance}</h2>
            <TransactionsTable transactions={transactions} />
        </div>
    );
};

export default AuthorDetail;
