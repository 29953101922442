import React, { useEffect, useState } from "react";
import { ref, getBlob } from "firebase/storage";
import Papa from "papaparse";
import { storage, db } from "../firebase-config";
import { collection, getDocs } from "firebase/firestore";
import TransactionRow from "./TransactionRow";
import { submitTransactions } from "../services/bankService";
import { parseCsvFile } from "../utils/parsingUtils";
import { PulseLoader } from "react-spinners";

const ParseCsv = ({ moveToNextStep }) => {
    const [transactions, setTransactions] = useState([]);
    const [editStates, setEditStates] = useState([]);
    const [patterns, setPatterns] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        const fetchPatterns = async () => {
            const querySnapshot = await getDocs(collection(db, "patterns"));
            const loadedPatterns = querySnapshot.docs.map((doc) => doc.data());
            setPatterns(loadedPatterns);
        };
        fetchPatterns();
    }, []);

    useEffect(() => {
        const handleParseCSV = async () => {
            const currentDate = new Date();
            currentDate.setMonth(currentDate.getMonth() - 1);
            const month = currentDate
                .toLocaleString("default", { month: "long" })
                .toLowerCase();
            const year = currentDate.getFullYear();
            const formattedFileName = `${month}-${year}.csv`;

            try {
                const { processedData } = await parseCsvFile(
                    formattedFileName,
                    patterns
                );
                setTransactions(processedData);
                setEditStates(processedData.map((trans) => trans.editable));
            } catch (err) {
                console.error(err.message);
                setError(err.message);
            }
        };

        if (patterns.length > 0) {
            handleParseCSV();
        }
    }, [patterns]);

    const handleToggleEdit = (index) => {
        const updatedEditStates = [...editStates];
        updatedEditStates[index] = !updatedEditStates[index];
        setEditStates(updatedEditStates);
    };

    const handleCategoryChange = (index, newCategory) => {
        const updatedTransactions = [...transactions];
        updatedTransactions[index].category = newCategory;
        setTransactions(updatedTransactions);
    };
    const handleSalesChannelChange = (index, newSalesChannel) => {
        setTransactions((currentTransactions) =>
            currentTransactions.map((transaction, idx) =>
                idx === index
                    ? { ...transaction, salesChannel: newSalesChannel }
                    : transaction
            )
        );
    };

    const handleDescriptionChange = (index, newDescription) => {
        const updatedTransactions = [...transactions];
        updatedTransactions[index].description = newDescription;
        setTransactions(updatedTransactions);
    };
    const handleSubmit = async () => {
        if (editStates.every((state) => !state)) {
            // Check if all transactions are accepted
            try {
                setLoading(true);
                await submitTransactions(transactions);
                setLoading(false);
                moveToNextStep();
            } catch (error) {
                console.error("Failed to submit transactions:", error);
                setError("Failed to submit transactions: " + error.message);
            }
        } else {
            alert("Please accept all transactions before submitting.");
        }
    };
    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <PulseLoader color="#36d7b7" />
            </div>
        );
    }

    return (
        <div>
            {transactions.length > 0 ? (
                <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 border-spacing-y-48">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 text-center">
                            <tr>
                                <th>Date</th>
                                <th>Amount</th>
                                <th>Bank Says</th>
                                <th>Category</th>
                                <th>Description</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {transactions.map((trans, index) => (
                                <TransactionRow
                                    key={index}
                                    transaction={trans}
                                    isEditable={editStates[index]}
                                    onCategoryChange={(newCategory) =>
                                        handleCategoryChange(index, newCategory)
                                    }
                                    onSalesChannelChange={(newSalesChannel) =>
                                        handleSalesChannelChange(
                                            index,
                                            newSalesChannel
                                        )
                                    }
                                    onDescriptionChange={(newDescription) =>
                                        handleDescriptionChange(
                                            index,
                                            newDescription
                                        )
                                    }
                                    onToggleEdit={() => handleToggleEdit(index)}
                                    onAccept={() => handleToggleEdit(index)}
                                />
                            ))}
                        </tbody>
                    </table>
                    <button
                        onClick={handleSubmit}
                        disabled={editStates.some((state) => state)} // Disable if any transaction is still editable
                        className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    >
                        Submit All Transactions
                    </button>
                </div>
            ) : (
                <p>No transactions to display</p>
            )}
        </div>
    );
};

export default ParseCsv;
