import React, { useState } from "react";

const ManualAssignment = ({
    parsedSpreadsheets,
    amazonTransactions,
    onAssignmentComplete,
}) => {
    const [assignments, setAssignments] = useState({});

    const handleAssignment = (spreadsheetIndex, transactionId) => {
        setAssignments((prev) => {
            const newAssignments = { ...prev };
            if (newAssignments[transactionId] === spreadsheetIndex) {
                // If it's already assigned to this spreadsheet, unassign it
                delete newAssignments[transactionId];
            } else {
                // Otherwise, assign it to this spreadsheet
                newAssignments[transactionId] = spreadsheetIndex;
            }
            return newAssignments;
        });
    };

    const handleSubmit = () => {
        // Check if all transactions are assigned to exactly one spreadsheet
        const assignmentCounts = amazonTransactions.reduce(
            (counts, transaction) => {
                counts[transaction.id] =
                    assignments[transaction.id] !== undefined ? 1 : 0;
                return counts;
            },
            {}
        );

        const unassigned = Object.entries(assignmentCounts).filter(
            ([_, count]) => count === 0
        );

        if (unassigned.length > 0) {
            const unassignedIds = unassigned.map(([id, _]) => id);
            const errorMessage = `The following transactions are not assigned: ${unassignedIds.join(
                ", "
            )}. Please ensure each transaction is assigned to exactly one spreadsheet.`;
            alert(errorMessage);
            return;
        }
        const newMarketDataSets = parsedSpreadsheets.map(
            (spreadsheet, index) => {
                const bankTransactions = {};
                amazonTransactions.forEach((transaction) => {
                    if (assignments[transaction.id] === index) {
                        bankTransactions[transaction.salesChannel] =
                            transaction;
                    }
                });

                const firstMarket = Object.values(spreadsheet.marketData)[0];
                const salesPeriod =
                    firstMarket &&
                    firstMarket[Object.keys(firstMarket)[0]][0]?.salesPeriod;

                return {
                    ...spreadsheet,
                    bankTransactions,
                    salesPeriod, // Add salesPeriod here
                };
            }
        );

        console.log("Submitting assignments", newMarketDataSets);
        onAssignmentComplete(newMarketDataSets);
    };

    const getSalesPeriod = (marketData) => {
        const firstMarket = Object.values(marketData)[0];
        return (
            firstMarket &&
            firstMarket[Object.keys(firstMarket)[0]][0]?.salesPeriod
        );
    };

    return (
        <div>
            <h2>Assign Amazon Transactions to Spreadsheets</h2>
            {parsedSpreadsheets.map((spreadsheet, index) => (
                <div key={index}>
                    <h3>
                        {spreadsheet.fileName} (Sales Period:{" "}
                        {getSalesPeriod(spreadsheet.marketData)})
                    </h3>
                    {amazonTransactions.map((transaction) => (
                        <div key={transaction.id}>
                            <input
                                type="checkbox"
                                id={`transaction-${transaction.id}-sheet-${index}`}
                                name={`transaction-${transaction.id}`}
                                checked={assignments[transaction.id] === index}
                                onChange={() =>
                                    handleAssignment(index, transaction.id)
                                }
                            />
                            <label
                                htmlFor={`transaction-${transaction.id}-sheet-${index}`}
                            >
                                {transaction.salesChannel} - $
                                {transaction.usdAmount} on{" "}
                                {transaction.date.toDateString()}
                            </label>
                        </div>
                    ))}
                </div>
            ))}
            <button
                onClick={handleSubmit}
                className="ml-2 px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-700"
            >
                Confirm Assignments
            </button>
        </div>
    );
};

export default ManualAssignment;
