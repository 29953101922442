import React, { useState, useMemo } from "react";

const SortIcon = ({ direction }) => {
    if (!direction) return null;
    return direction === "asc" ? " 🔼" : " 🔽";
};

const SortableTable = ({
    columns,
    data,
    defaultSortField,
    defaultSortDirection = "asc",
    onDeleteSelected,
}) => {
    const [sortField, setSortField] = useState(defaultSortField);
    const [sortDirection, setSortDirection] = useState(defaultSortDirection);
    const [selectedIds, setSelectedIds] = useState(new Set());

    const sortedData = useMemo(() => {
        return [...data].sort((a, b) => {
            const isAsc = sortDirection === "asc";
            let valueA = a[sortField];
            let valueB = b[sortField];

            // Special handling for date fields
            if (
                columns.find(
                    (col) => col.key === sortField && col.type === "date"
                )
            ) {
                valueA = new Date(valueA);
                valueB = new Date(valueB);
            }

            if (valueA < valueB) {
                return isAsc ? -1 : 1;
            }
            if (valueA > valueB) {
                return isAsc ? 1 : -1;
            }
            return 0;
        });
    }, [data, sortField, sortDirection]);

    const handleSort = (field) => {
        const isAsc = sortField === field && sortDirection === "asc";
        setSortDirection(isAsc ? "desc" : "asc");
        setSortField(field);
    };

    const handleSelectionChange = (id) => {
        const newSelectedIds = new Set(selectedIds);
        if (newSelectedIds.has(id)) {
            newSelectedIds.delete(id);
        } else {
            newSelectedIds.add(id);
        }
        setSelectedIds(newSelectedIds);
    };

    const handleDeleteSelected = () => {
        onDeleteSelected(Array.from(selectedIds));
        setSelectedIds(new Set()); // Clear selections after delete
    };
    return (
        <>
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-300 text-center">
                    <tr>
                        <th>
                            <input type="checkbox" disabled />
                        </th>
                        {columns.map(({ key, label }) => (
                            <th
                                key={key}
                                onClick={() => handleSort(key)}
                                className="cursor-pointer py-2"
                            >
                                {label}{" "}
                                <SortIcon
                                    direction={
                                        sortField === key ? sortDirection : null
                                    }
                                />
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {sortedData.map((row, idx) => (
                        <tr key={idx} className="even:bg-gray-200">
                            <td>
                                <input
                                    type="checkbox"
                                    checked={selectedIds.has(row.id)}
                                    onChange={() =>
                                        handleSelectionChange(row.id)
                                    }
                                />
                            </td>
                            {columns.map(({ key }) => (
                                <td key={key} className="px-3 py-2">
                                    {row[key]}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
            <button
                onClick={handleDeleteSelected}
                className="mt-2 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
            >
                Delete Selected
            </button>
        </>
    );
};

export default SortableTable;
