import React from "react";
import CategoriesDropdown from "./CategoriesDropdown";
import SalesChannelDropdown from "./SalesChannelDropdown";

const TransactionRow = ({
    transaction,
    isEditable,
    onCategoryChange,
    onDescriptionChange,
    onSalesChannelChange,
    onToggleEdit,
    onAccept,
}) => {
    return (
        <tr>
            <td className="mb-8 pb-8 px-2">{transaction["<Date>"]}</td>
            <td className="text-center mb-8 pb-8 px-2">
                ${transaction["<Withdrawal Amount>"]}
                {transaction["<Deposit Amount>"]}
            </td>
            <td className="mb-8 pb-8 px-2">
                {transaction["<Additional Info>"]}
            </td>
            <td className="mb-8 pb-8">
                {isEditable ? (
                    <CategoriesDropdown
                        defaultValue={transaction.category}
                        onChange={(e) => onCategoryChange(e.target.value)}
                    />
                ) : (
                    transaction.category
                )}
                <br />
                {transaction.category === "Publishing Revenue" ? (
                    isEditable ? (
                        <SalesChannelDropdown
                            defaultValue={transaction.salesChannel}
                            onChange={(e) =>
                                onSalesChannelChange(e.target.value)
                            }
                        />
                    ) : (
                        <span className="px-2 py-1 rounded bg-gray-100 text-gray-800">
                            {transaction.salesChannel || "No Sales Channel"}
                        </span>
                    )
                ) : null}
            </td>
            <td className="mb-8 pb-8">
                {isEditable ? (
                    <input
                        type="text"
                        value={transaction.description}
                        onChange={(e) => onDescriptionChange(e.target.value)}
                        className="p-2 w-full rounded border-2 border-gray-300 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 transition duration-150 ease-in-out"
                        placeholder="Enter description"
                    />
                ) : (
                    <span className="px-2 py-1 rounded bg-gray-100 text-gray-800">
                        {transaction.description}
                    </span>
                )}
            </td>
            <td className="text-center mb-8 pb-8">
                {isEditable ? (
                    <button
                        onClick={onAccept}
                        className="mx-1 px-2 py-1 bg-green-500 text-white rounded hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
                    >
                        &#10003;
                    </button>
                ) : (
                    <button
                        onClick={onToggleEdit}
                        className="mx-1 px-2 py-1 bg-gray-200 text-black rounded hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-opacity-50"
                    >
                        ✏️
                    </button>
                )}
            </td>
        </tr>
    );
};

export default TransactionRow;
