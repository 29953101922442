import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
    getStaffMember,
    getTransactionsByStaff,
} from "../services/staffService";
import TransactionsTable from "./TransactionsTable";
import { calculateStaffWorkingBalance } from "../utils/staffUtils"; // Assuming the same utility function can be used

const StaffDetail = () => {
    const { staffSlug } = useParams();
    const [staff, setStaff] = useState(null);
    const [balance, setBalance] = useState("Calculating..."); // Default text or loading state
    const [transactions, setTransactions] = useState([]);

    useEffect(() => {
        if (staffSlug) {
            getStaffMember(staffSlug).then((staffData) => {
                setStaff(staffData);
                if (staffData) {
                    calculateStaffWorkingBalance(staffData).then(setBalance);
                    getTransactionsByStaff(staffSlug).then(setTransactions);
                }
            });
        }
    }, [staffSlug]);

    if (!staff) {
        return <div>Loading staff details...</div>;
    }

    return (
        <div>
            <h2>{staff.name}'s Balance</h2>
            <h2>Balance: ${balance}</h2>
            <TransactionsTable transactions={transactions} />
        </div>
    );
};

export default StaffDetail;
