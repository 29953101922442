import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { storage, db } from "../firebase-config";
import { collection, getDocs, query, where } from "firebase/firestore";
import { ref, getMetadata } from "firebase/storage";
import { parseCsvFile } from "../utils/parsingUtils";
import AccountingUpload from "./AccountingUpload";
import ParseCsv from "./ParseCsv";
import CategorizeTransactions from "./CategorizeTransactions";
import ReviewBalances from "./ReviewBalances";
import ProgressBar from "./ProgressBar";
import NavigationButtons from "./NavigationButtons";

const stepComponents = [
    AccountingUpload,
    ParseCsv,
    CategorizeTransactions,
    ReviewBalances,
];

const AccountingMain = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const [autoMode, setAutoMode] = useState(true); // State variable for the toggle switch
    const [allTransactionsCategorized, setAllTransactionsCategorized] =
        useState(false); // State variable for checking if all transactions are categorized
    const currentDate = useMemo(() => {
        const date = new Date();
        date.setMonth(date.getMonth() - 1);
        return date;
    }, []);

    const handleNext = () => {
        if (currentStep < stepComponents.length - 1) {
            setCurrentStep(currentStep + 1);
        }
    };

    const handleBack = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    };

    const toggleAutoMode = () => setAutoMode((prev) => !prev); // Toggle the state

    const checkFileExists = async () => {
        const month = currentDate
            .toLocaleString("default", { month: "long" })
            .toLowerCase();
        const year = currentDate.getFullYear();
        const formattedFileName = `${month}-${year}.csv`;
        const storageRef = ref(storage, `bank-data/${formattedFileName}`);

        try {
            const fileSnapshot = await getMetadata(storageRef);
            return !!fileSnapshot.size; // If file exists and has size greater than 0, return true
        } catch (error) {
            return false; // File does not exist or other errors
        }
    };
    const validateParseCompletion = async (month, year) => {
        const startDate = new Date(year, month, 1);
        const endDate = new Date(year, month + 1, 0);

        const querySnapshot = await getDocs(
            query(
                collection(db, "bankTransactions"),
                where("date", ">=", startDate),
                where("date", "<=", endDate)
            )
        );

        return querySnapshot.size; // Returns the count of transactions for that month
    };
    useEffect(() => {
        if (!autoMode) return; // Skip automated step changes in manual mode
        const initializeProcess = async () => {
            const month = currentDate
                .toLocaleString("default", { month: "long" })
                .toLowerCase();
            const year = currentDate.getFullYear();
            const formattedFileName = `${month}-${year}.csv`;

            const fileExists = await checkFileExists();
            if (fileExists) {
                const { rawData } = await parseCsvFile(formattedFileName, []);
                const transactionsCount = await validateParseCompletion(
                    currentDate.getMonth(),
                    currentDate.getFullYear()
                );

                if (
                    transactionsCount === rawData.length &&
                    allTransactionsCategorized
                ) {
                    setCurrentStep(3); // Go to Step 4: Review Balances if all transactions are categorized
                } else if (transactionsCount === rawData.length) {
                    setCurrentStep(2); // Skip to Step 3: Categorize Transactions
                } else {
                    setCurrentStep(1); // Go to Step 2: Parse CSV
                }
            } else {
                setCurrentStep(0); // Stay at Step 1: Upload File
            }
        };
        initializeProcess(currentStep, autoMode, allTransactionsCategorized);
    }, [
        currentStep,
        allTransactionsCategorized,
        autoMode,
        checkFileExists,
        currentDate,
    ]);

    return (
        <div>
            <div className="mb-8">
                <ProgressBar
                    currentStep={currentStep}
                    maxSteps={stepComponents.length}
                />
            </div>
            <label className="inline-flex items-center cursor-pointer">
                <input
                    type="checkbox"
                    checked={autoMode}
                    onChange={toggleAutoMode}
                    className="sr-only peer"
                />
                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                    Auto Mode
                </span>
            </label>
            {React.createElement(stepComponents[currentStep], {
                key: currentStep,
                moveToNextStep: handleNext,
                currentStep: currentStep,
                setAllTransactionsCategorized: setAllTransactionsCategorized,
            })}
            <NavigationButtons
                onBack={handleBack}
                onNext={handleNext}
                canGoBack={currentStep > 0}
                canGoNext={currentStep < stepComponents.length - 1}
            />
            <Link to="/accounting/patterns/add" className="block mt-4 text-sm">
                Add Patterns
            </Link>
        </div>
    );
};

export default AccountingMain;
